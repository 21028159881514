import React from "react";
import {GridList,GridListTile} from "@material-ui/core";
// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import {Transition} from "../Functions/Animations";
import Button from "../components/CustomButtons/Button";
import {Close} from "@material-ui/icons";
import {connect}from 'react-redux';
import modalStyle from "../assets/jss/material-kit-react/modalStyle";
import GridContainer from "../components/Grid/GridContainer";
import GridItem from "../components/Grid/GridItem";
import {DropzoneArea} from "material-ui-dropzone";
import {Backup} from "@material-ui/icons";
import {getImages, uploadImage} from "../Store/Actions/Admin/images";
import {CircularProgress} from "@material-ui/core";
import Danger from "../components/Typography/Danger";


class ImageModal extends React.Component{

    state= {
      view:'gallery',
      selectedImage:null,
      files:[],
        error:''
    };

    loadImage=(files)=>{
        this.setState({files});
    };


    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.selectedImage===null&& this.props.selectedImage !==null){
            this.setState({selectedImage:this.props.selectedImage})
        }
    }

    handleImageClick=(url)=>{
        this.setState({selectedImage:url})
    };

    renderView=()=>{
      const {view, error} = this.state;
        const classes = this.props.classes;
      if(view==='gallery'){
          const {images}=this.props;
          const {selectedImage}=this.state;
          if(images){

              return (
                  <GridContainer>
                      <GridItem xs={12}>
                          <Button color={'primary'} size={'sm'} onClick={()=>this.setState({view:'upload'})} >
                              <Backup/>
                              Upload
                          </Button>
                      </GridItem>
                      <GridList cols={12}>
                          {Object.keys(images).map(image=>{
                              const {name,url}=images[image];
                              return (
                                  <GridListTile key={name} rows={0.5} onClick={()=>this.handleImageClick(url)} >
                                      <img className={selectedImage===url?classes.selectedImage:classes.image} alt={name} src={url}/>
                                  </GridListTile>
                              )
                          })}
                      </GridList>
                  </GridContainer>

              )

              /*
              return (
                  <GridContainer>
                      {Object.keys(images).map(image=>{
                          const {name,url}=images[image];
                          return (
                              <GridItem xs={12} sm={12} md={1} key={image}>
                                  {selectedImage===url?
                                    <Button size={'sm'}>
                                        <img alt={`logo/${name}`} src={url} style={{maxWidth:70}}/>
                                    </Button>:
                                      <Button size={'sm'} simple onClick={()=>this.handleImageClick(url)}>
                                          <img alt={`logo/${name}`} src={url} style={{maxWidth:70}}/>
                                      </Button>
                                  }

                              </GridItem>
                          )
                      })}
                      <GridItem xs={12}>
                          <Button color={'primary'} size={'sm'} onClick={()=>this.setState({view:'upload'})} >
                              <Backup/>
                              Upload
                          </Button>
                      </GridItem>
                  </GridContainer>
              )

               */
          }else{
              return (
                  <div>
                      <h3>No Images in gallery!</h3>
                      <Button color={'primary'} size={'sm'} onClick={()=>this.setState({view:'upload'})} >
                          <Backup/>
                          Upload
                      </Button>
                  </div>
              )
          }

      }
      else if(view==='upload'){
            return (
                <GridContainer>
                    <GridItem xs={12} container alignContent={'center'}>
                        <Danger>{error}</Danger>
                        <Button size={'sm'} color={'primary'} onClick={()=>this.setState({view:'gallery'})}>Gallery</Button>
                        <DropzoneArea
                            acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                            filesLimit={1}
                            maxFileSize={10000000}
                            onChange={files=>this.loadImage(files)}
                        />
                    </GridItem>
                </GridContainer>
            )
      }
      else{
          return (
              <GridItem container justify={'center'} alignItems={'center'}>
                  <CircularProgress size={100}/>
              </GridItem>
          )
      }

    };

    handleSave=()=>{
        const {view, selectedImage, files}=this.state;
        if(view==='upload'){
            this.setState({view:'loading'});

            this.props.uploadImage(files,this.props.mode,res=>{
                if(res.status===1){
                    this.props.getImages(resI=>{
                        resI.status===1?
                            this.setState({
                            view:'gallery',
                            error:'',
                            selectedImage: res.url
                        }):
                            this.setState({
                                view: 'upload',
                                error:resI.msg
                            })
                    })

                }else{
                    this.setState({view:'upload',error:res.msg})
                }



            })
        }else{
           this.props.onSave(selectedImage);
        }
    };


    render(){
        const {show,close, Title,classes}=this.props;

        return (
            <Dialog
                maxWidth={'xl'}
                fullWidth={true}
                open={show}
                classes={{
                    root: classes.center,
                    paper: classes.modal
                }}
                style={{zIndex:9999}}
                TransitionComponent={Transition}
                keepMounted
                onClose={close}
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description">
                <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                >
                    <IconButton
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={close}
                    >
                        <Close className={classes.modalClose} />
                    </IconButton>
                    <h4 className={classes.modalTitle}>{Title!==undefined?Title:'Blog Images'}</h4>
                    {this.state.view==='upload' && (
                        <div>
                            <p>* NB - Remember that the compressor will run on any image size larger than 500kb.</p>
                            <a href={'https://tinypng.com/'} target={'_blank'}>Use this link to compress images lower than 500kb</a>
                        </div>
                    )}
                </DialogTitle>
                <DialogContent
                    id="modal-slide-description"
                    //className={classes.modalBody}
                >
                    <div style={{width:'100%'}}>
                    {this.renderView()}
                    </div>
                </DialogContent>
                <DialogActions
                    className={classes.modalFooter + " " + classes.modalFooterCenter}
                >
                    <Button round size={'sm'} onClick={close}>Cancel</Button>
                    <Button color="success" round size={'sm'} onClick={()=>this.handleSave()}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const mapStateToProps=(state)=>{
    return {images:state.images.images}
};

export default connect(mapStateToProps,{uploadImage,getImages})(withStyles(modalStyle)(ImageModal))



