import React from "react";
import {Route, Router, Switch} from "react-router-dom";
import history from "./history";

// Components
import "./assets/css/material-dashboard-react.css";
import Login from "./Screens/admin/login";
import Dashboard from './Screens/admin/home';

class App extends React.Component{

    render() {
        return (
            <div>
                <Router history={history}>
                    <Switch>
                        <Route path="/" exact component={Login}/>
                        <Route path="/admin"  component={Dashboard}/>
                    </Switch>
                </Router>
            </div>
        )
    }
}

export default App;