import {ADMIN_LOGOUT, ADMIN_LOGIN, ADMIN_UPDATE_DETAILS, ADMIN_UPDATE_IMAGE} from "../../types";
import {auth,database} from "../../../Firebase";
import API from "../Api";

export const LogAdminIn=({email,password}, callback)=>dispatch=>{

    auth.signInWithEmailAndPassword(email,password)
        .then(user=>{
            const {displayName,uid,photoURL,lastLogin}=user.user;

            const ref = database.ref('admin');
            ref.once('value').then(snap=>{
                const adminObj=snap.val();
                if(adminObj[uid]){

                    dispatch({type:ADMIN_LOGIN,payload:{displayName,email,uid,photoURL,lastLogin}});
                    callback({status:1})
                }else{
                    callback({status:2,msg:'You are not authorised to access this portal!'})
                }
            }).catch(err=>callback({status:2,msg:err.message}))


        })
        .catch(err=>callback({status:2,msg:err.message}));


};

export const CheckLogin=(callback)=>dispatch=>{

    auth.onAuthStateChanged(user=>{
        if(user){
            const {uid,displayName,photoURL,email,lastLogin}=user;
            const adminCheck=database.ref(`admin/${uid}`).once('value',snap=>snap.val(),err=>false)
            if(adminCheck){
                dispatch({type:ADMIN_LOGIN,payload:{displayName,email,uid,photoURL,previousLogIn:lastLogin}});
                callback({status:1})
            }else{
                callback({status:2})
            }
        }else{
            callback({status:2})
        }
    })
};

export const ForgotPassword=({email},callback)=>dispatch=>{
  auth.sendPasswordResetEmail(email).then(res=>{
      callback({status:1});
  }).catch(err=>callback({status:2,msg:err.message}));
};

export const AdminLogout=(callback)=>dispatch=>{
  auth.signOut()
      .then(res=>{
      dispatch({type:ADMIN_LOGOUT});
      callback({status:1})})
      .catch(err=>callback({status:2,msg:err.message}));

};



export const updateAdminDetails=({displayName,email},callback)=>async (dispatch)=>{

    const user = auth.currentUser;

    const response= await API.post('/profileUpdate',{uid:user.uid,email,displayName});

    if(response.data.status===1){
        dispatch({type:ADMIN_UPDATE_DETAILS,payload:{displayName,email}});
        callback({status:1})
    }else{
        callback({status:2,msg:'ERROR-Could not update details'});
    }
};

export const updatePhotoUrl=(url,callback)=>async dispatch=>{

    const user = auth.currentUser;

    const response = await API.post('/profileAvatarUpdate',{photoUrl:url,uid:user.uid})

    if(response.data.status===1){
        dispatch({type:ADMIN_UPDATE_IMAGE,payload:url});
        callback({status:1})
    }else{
        callback({status:2,msg:response.data.msg})
    }

};






