import React from "react";
import { connect} from 'react-redux';
import {Add, DeleteForever}from '@material-ui/icons'
import {Grid, Tooltip} from "@material-ui/core";
import {MultipleSelect} from "react-select-material-ui";
import ChipInput from 'material-ui-chip-input'

// components
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import CustomInput from "../../../../components/CustomInput/CustomInput";
import {DateTimePicker} from "../../../../components/DateTimePicker";
import Button from "../../../../components/CustomButtons/Button";
import {MainSwitch} from "../../../../components/Switch";
import ImageModal from "../../../../My Components/ImageModal";
import {uploadImage} from "../../../../Store/Actions/Admin/images";
import WYSIWYG from "../../../../My Components/WYSIWYG";
import Validation from "../../../../Functions/Validation";
import Danger from "../../../../components/Typography/Danger";


class BlogComponent extends React.Component{

    state={
        title:'',
        scheduleDate:new Date(new Date().setHours(24,0,0,0)),
        publish:false,
        images:[],
        imageModal:false,
        content:'<p>Some content here...</p>',
        categories:[],
        lastEdited:new Date(),
        allowComments:false,
        tags:[],
        errors:null
    };

    componentDidMount() {
        const {tags,mode,title,scheduleDate, publish,images,content,categories,lastEdited, allowComments}=this.props;
        if(mode==='edit'){
            this.setState({
                title,
                scheduleDate:new Date(scheduleDate),
                publish,
                images,
                categories,
                content,
                lastEdited:new Date(lastEdited),
                allowComments,
                tags:tags!==undefined?tags:[]
            })
        }
    }

    handleChange=name=>event=>{
        if(name==='publish' || name==='allowComments'){
            this.setState({[name]:event.target.checked})
        }else{
            this.setState({[name]:event.target.value})
        }


    };

    handleDateChange=date=>{
        this.setState({scheduleDate:date})
    };

    handleImage=(url)=>{
      const {images}=this.state;
      if(url !==''){
          images.push(url);
          this.setState({images,imageModal:false});
      }else{
          this.setState({imageModal:false});
      }

    };

    removeImage=(image)=>{
        const {images}=this.state;
        const newImages=images.filter(i=>i!==image?image:null);
        this.setState({images:newImages});
    };

    renderImages=()=>{
      const {images}=this.state;
      if(images.length>0){

          return (
              <Grid container direction={'row'}>
                  {images.map((image,index)=>{
                      return (
                          <div key={index} style={{padding:25}}>
                              <div style={{height:100, width:100}}>
                                <img alt={'img'} src={image} style={{maxWidth:100, maxHeight:100}}/>
                              </div>
                              <p>{index===0?'Main Blog Image':'Image no '+(index+1)}</p>
                              <Tooltip title={'Delete Image'}>
                                  <Button onClick={()=>this.removeImage(image)} justIcon size={'sm'} color={'danger'} round>
                                      <DeleteForever/>
                                  </Button>
                              </Tooltip>
                          </div>
                      )
                  })}
              </Grid>
          )

      }else{
          return (
              <Grid container justify={'center'}>
                  <h4>No Images selected</h4>
              </Grid>
          )
      }
    };

    handleContentChange=(content)=>{
        this.setState({content});
    };

    handleTagsAdd=(tag)=>{
        const tags = this.state.tags;
        tags.push(tag);
        this.setState({tags});
    }

    handleDeleteTags=(tag,index)=>{
        const tags = this.state.tags;
        const newTags =tags.filter((v,i)=>v!==tag);
        this.setState({tags:newTags});

    }

    handleSave=()=>{
        const {scheduleDate,title, images, categories,content, publish, allowComments,tags}=this.state;
        const date = scheduleDate instanceof Date ? Date.parse(scheduleDate): Date.parse(scheduleDate.format());
        // need to add validation

        const data = {
            title,
            date,
            categories,
            images,
            content,
            publish,
            allowComments,
            tags
        }

        const validation = Validation(data);
        if(!validation){
            this.setState({errors:null});
            this.props.onSubmit(data);
        }else{
            this.setState({errors:validation});
        }
    };


    renderErrors=()=>{
        const errors=this.state.errors;
        if(errors){
            return (
                <ul>
                    {Object.keys(errors).map(error=>(
                        <li>
                            <Danger>{errors[error]}</Danger>
                        </li>
                    ))}
                </ul>
            )
        }
    }


    render(){
        const {tags,title,scheduleDate, publish,imageModal,content, lastEdited, categories, allowComments}=this.state;
        const {classes}= this.props;
        return (
            <GridContainer>

                <ImageModal
                    show={imageModal}
                    mode={'banner'}
                    close={()=>this.setState({imageModal:false})}
                    classes={classes}
                    onSave={this.handleImage}
                />
                <GridItem xs={12}>
                    <GridContainer>
                        {/*Title*/}
                        <GridItem xs={12} sm={12} md={12} style={{marginBottom:50}}  >
                            <CustomInput
                                id="title"
                                inputProps={{
                                    value:title,
                                    placeholder: "Title",
                                    onChange:(this.handleChange('title')),
                                }}
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} style={{marginBottom:50}}>
                            <ChipInput
                                placeholder={'Tags'}
                                label={'Blog Tags for SEO'}
                                onAdd={this.handleTagsAdd}
                                onDelete={this.handleDeleteTags}
                                value={tags}
                                fullWidth
                            />
                        </GridItem>
                        {/*Categories*/}
                        <GridItem xs={12} md={4}>
                            <MultipleSelect
                                values={categories}
                                options={this.props.categoriesSettings}
                                onChange={categories=>this.setState({categories})}
                            />
                        </GridItem>
                        {/*Schedule Date*/}
                        <GridItem xs={12} sm={6} md={4} >
                            <DateTimePicker
                                placeholder={'Select A schedule'}
                                value={scheduleDate}
                                onChange={this.handleDateChange}
                                label={'Schedule Date'}
                            />
                        </GridItem>
                        {/*Publish & Allow Comments*/}
                        <GridItem xs={12} sm={6} md={4} >
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>
                                    <MainSwitch
                                        label={'Publish'}
                                        checked={publish}
                                        value={'publish'}
                                        onChange={this.handleChange('publish')}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <MainSwitch
                                        label={'Allow Comments'}
                                        checked={allowComments}
                                        value={'allowComments'}
                                        onChange={this.handleChange('allowComments')}
                                    />
                                </GridItem>
                            </GridContainer>

                        </GridItem>
                        {/*Images*/}
                        <GridItem xs={12}>
                            <Grid container justify={'flex-end'}>
                                <Button onClick={()=>this.setState({imageModal:true})} size={'sm'} round color={'success'}><Add/>Add Images</Button>
                            </Grid>
                            <p style={{textAlign:'center'}}>When adding more than one image, the blog will render all the images in a carousel.</p>
                            {this.renderImages()}
                        </GridItem>
                        {/*Content*/}
                        <GridItem xs={12}>
                           <WYSIWYG
                            content={content}
                            onChange={this.handleContentChange}
                           />
                        </GridItem>
                        <p>Last edited: {lastEdited.toString()}</p>
                    </GridContainer>
                </GridItem>
                <GridItem xs={12}>
                    {this.renderErrors()}
                    <Button block onClick={()=>this.handleSave()} size={'sm'} color={'success'} round>
                        Save
                    </Button>
                </GridItem>
            </GridContainer>
        )
    }
}

const mapStateToProps=(state)=>{
    return {
        categoriesSettings:state.data.categories
    }
};


export default connect(mapStateToProps, {uploadImage})(BlogComponent);
