import React from "react";
import {connect} from 'react-redux';
import {InputAdornment, GridList,GridListTile,GridListTileBar, IconButton} from "@material-ui/core";
import {withStyles}from '@material-ui/core/styles';
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Button from "../../../components/CustomButtons/Button";
import {AddAlarm, LibraryAdd, Search, Close} from "@material-ui/icons";
import {Loading} from "../../../components/Loading";
import CustomInput from "../../../components/CustomInput/CustomInput";
import ImageModal from "../../../My Components/ImageModal";
import ConfirmModal from "../../../My Components/ConfrimModal";
import {deleteImage,getImages} from "../../../Store/Actions/Admin/images";
import Snackbar from "../../../components/Snackbar/Snackbar";


const Styles=(theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: 500,
        height: 450,
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },
});



class GalleryPage extends React.Component{

    state={
        search:'',
        loading:false,
        imageModal:false,
        confirmModal:false,
        deleteImageId:null,
        deleteImageName:null,
        notif:false,
        notifType: 'warning',
        notifMsg:''
    };

    renderImages=()=>{
        const {images,classes}=this.props;
        const imageArr=images!==null?Object.keys(images):[];
        const search = this.state.search.toLowerCase();
      if(imageArr.length>0){


          return (
              <GridList cellHeight={200}>
                  {imageArr.map((image)=>{
                      const {name,url, type}=images[image];
                      if(name.toLowerCase().includes(search)){
                          return (
                              <GridListTile cols={0.33} key={image}>
                                  <img src={url} alt={name}/>
                                  <GridListTileBar
                                      title={name}
                                      subtitle={<span>by: {type}</span>}
                                      actionIcon={
                                          <IconButton
                                              aria-label={`info about ${name}`}
                                              className={classes.icon}
                                              onClick={()=>this.setState({deleteImageId:image,deleteImageName:name,confirmModal:true})}
                                          >
                                              <Close />
                                          </IconButton>
                                      }
                                  />
                              </GridListTile>
                          )
                      }
                  })}
              </GridList>
          )



          /*
          return(
              <GridContainer>
                  {imageArr.map((image,index)=>{
                      const {name,url, type}=images[image];
                      if(name.toLowerCase().includes(search)){
                          return (
                              <GridItem xs={12} sm={12} md={4} lg={2} key={index}>
                                  <Card>
                                      <CardBody>
                                          <div style={{height:'100px'}}>
                                              <img
                                                  //className={classes.cardImgTop}
                                                  alt={name}
                                                  src={url}
                                                  style={{width: "100%", display: "block"}}
                                              />
                                          </div>
                                          <p style={{paddingTop:5,fontSize:20}}>
                                              {name.substring(0,8)+'...'}<br/>
                                              <span style={{fontSize:10}}>File Type: {type!==undefined?type:'unknown'}</span>
                                          </p>
                                          <Button
                                              onClick={()=>this.setState({deleteImageId:image,deleteImageName:name,confirmModal:true})}
                                              size={'sm'}
                                              color={'danger'}
                                          >
                                              Delete
                                          </Button>
                                      </CardBody>
                                  </Card>

                              </GridItem>
                          )
                      }



                  })}
              </GridContainer>
          )

           */

      }else{
          return (
              <div>
                  <h4>No images loaded yet!</h4>
              </div>
          )
      }
    };

    handleChange=name=>event=>{
        this.setState({[name]:event.target.value})
    };


    confirmDelete=()=>{
        const {deleteImageId,deleteImageName}=this.state;
        this.setState({loading:true});
       this.props.deleteImage(deleteImageName,deleteImageId,res=>{
            res.status===1?
                this.setState({
                    loading:false,
                    notif:true,
                    notifType:'success',
                    notifMsg:'Image deleted successfully!',
                    deleteImageId: null,
                    deleteImageName: null,
                    confirmModal:false
                }):
                this.setState({
                    loading:false,
                    notif:true,
                    notifType:'danger',
                    notifMsg:res.msg
                })
        })
    };


    render(){
        const {classes}=this.props;
        const {loading,search,imageModal, confirmModal, notif,notifMsg,notifType}=this.state;
        return (
            <div>
                <Loading
                   show={loading}
                />
                <ImageModal
                    show={imageModal}
                    mode={'banner'}
                    close={()=>this.setState({imageModal:false})}
                    classes={classes}
                    onSave={()=>this.setState({imageModal: false})}
                />
                <Snackbar
                    icon={AddAlarm}
                    place={'br'}
                    color={notifType}
                    open={notif}
                    close
                    closeNotification={()=>this.setState({notif: false, notifType: 'warning',notifMsg: ''})}
                    message={notifMsg}
                />
                <ConfirmModal
                    open={confirmModal}
                    close={()=>this.setState({confirmModal: false, deleteImageId:null,deleteImageName:null})}
                    Title={'Confirm delete image'}
                    saveText={'Delete'}
                    saveColor={'danger'}
                    content={'Are you sure you want to delete this image ?'}
                    saveAction={this.confirmDelete}
                />
                <GridContainer>
                    <GridItem xs={12} sm={12} md={8}>
                        <Button onClick={()=>this.setState({imageModal: true})}  color={'success'} size={'sm'}>
                            <LibraryAdd/>
                            Add Image
                        </Button>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                            id="regular"
                            inputProps={{
                                placeholder: "Search",
                                onChange:(this.handleChange('search')),
                                endAdornment:(
                                    <InputAdornment position={'end'}>
                                        <Search/>
                                    </InputAdornment>
                                )
                            }}
                            formControlProps={{
                                fullWidth: true
                            }}
                        />
                    </GridItem>
                </GridContainer>
                {this.renderImages()}
            </div>
        )
    }
}

const mapStateToProps=(state)=>{

    return {
        images:state.images.images
    }


};

export default connect(mapStateToProps, {deleteImage, getImages})(withStyles(Styles)(GalleryPage));