import React from "react";
import {Doughnut,Line, HorizontalBar} from "react-chartjs-2";
// install with chart-js

const LineGraph=({labels,series,label})=>{


    const data ={
        labels: labels,
        datasets: [
            {
                label: label,
                fill: false,
                lineTension: 0.1,
                backgroundColor: '#eff',
                borderColor: '#eee',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: series
            }
        ]
    }

    return (
        <Line
            data={data}
            options={{
                legend: {
                    labels: {
                        fontColor: 'white'
                    }
                },
                scales: {
                    xAxes: [{
                        ticks: {
                            fontColor: "white",
                        }
                    }],
                    yAxes: [{
                        ticks: {
                            fontColor: "white",
                            beginAtZero: true,
                        }
                    }]
                }
            }}
        />
    )
}

const DoughnutGraph=({labels,series})=>{

    const data ={
        labels,
        datasets:[{
            data:series,
            backgroundColor:['#112c7e','#a0d0da'],
            hoverBackgroundColor:['#a2a9b4','#1241ad']
        }]
    }
    return (
        <Doughnut
            data={data}
        />
    )

}

const HorizontalGraph=({labels,series,label})=>{

    const data={
        labels,
        datasets: [
            {
                label: label,
                backgroundColor: '#112c7e',
                borderColor: '#a0d0da',
                borderWidth: 1,
                hoverBackgroundColor: '#112c7e',
                hoverBorderColor:  '#a0d0da',
                data: series
            }
        ]
    }
    return (
        <HorizontalBar
            data={data}
            options={{
                legend: {
                    labels: {
                        fontColor: 'white'
                    }
                },
                scales: {
                    xAxes: [{
                        ticks: {
                            fontColor: "white",
                        }
                    }],
                    yAxes: [{
                        ticks: {
                            fontColor: "white",
                            beginAtZero: true,
                        }
                    }]
                }
            }}
        />
    )

}

export {LineGraph, DoughnutGraph, HorizontalGraph}