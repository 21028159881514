import React from "react";
import {connect}from 'react-redux';
import Card from "../../../../components/Card/Card";
import CardHeader from "../../../../components/Card/CardHeader";
import CardBody from "../../../../components/Card/CardBody";
import GridItem from "../../../../components/Grid/GridItem";
import {reduxForm, Field} from "redux-form";
import GridContainer from "../../../../components/Grid/GridContainer";
import Danger from "../../../../components/Typography/Danger";
import {RenderInput} from "../../../../Functions/RenderInputs";
import {Grid} from "@material-ui/core";
import Button from "../../../../components/CustomButtons/Button";


class SocialLinksForm extends React.Component{

    state={
        error:'',

    };

    onSubmit=(formValues)=>{
        const {facebook,instagram,youtube,twitter,contactEmail}=formValues;
        const data={
          facebook:facebook!==undefined?facebook:'',
          instagram:instagram!== undefined?instagram:'',
          youtube:youtube!==undefined?youtube:'',
          twitter:twitter!==undefined?twitter:'',
          contactEmail:contactEmail!==undefined?contactEmail:''
        };

        this.props.onSubmit(data);
    };

    render(){
        const {error}=this.state;
        return (
            <GridItem xs={12} sm={12} md={6}>
                <Card>
                    <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                        <CardHeader color={'warning'}>
                            Social Links
                        </CardHeader>
                        <CardBody>
                            <Danger>{error}</Danger>
                            <p>Below are the links that will appear in your footer of your blog, if you dont want the link to show simply leave the field blank.</p>
                            <GridContainer>
                                <GridItem container direction={'row'} justify={'space-around'} sm={12}>
                                    <Field
                                        id={'facebook'}
                                        name={'facebook'}
                                        type={'link'}
                                        label={'Facebook'}
                                        placeholder={'https://facebook.com/mysite'}
                                        component={RenderInput}
                                    />
                                </GridItem>
                                <GridItem container direction={'row'} justify={'space-around'} sm={12}>
                                    <Field
                                        id={'instagram'}
                                        name={'instagram'}
                                        type={'link'}
                                        label={'Instagram'}
                                        placeholder={'https://instagram.com/mysite'}
                                        component={RenderInput}
                                    />
                                </GridItem>
                                <GridItem container direction={'row'} justify={'space-around'} sm={12}>
                                    <Field
                                        id={'youtube'}
                                        name={'youtube'}
                                        type={'link'}
                                        label={'Youtube'}
                                        placeholder={'https://youtube.com/mysite'}
                                        component={RenderInput}
                                    />
                                </GridItem>
                                <GridItem container direction={'row'} justify={'space-around'} sm={12}>
                                    <Field
                                        id={'twitter'}
                                        name={'twitter'}
                                        type={'link'}
                                        label={'Twitter'}
                                        placeholder={'https://twitter.com/mysite'}
                                        component={RenderInput}
                                    />
                                </GridItem>
                                <GridItem container direction={'row'} justify={'space-around'} sm={12}>
                                    <Field
                                        id={'contactEmail'}
                                        name={'contactEmail'}
                                        type={'link'}
                                        label={'Contact Email'}
                                        placeholder={'name@email.com'}
                                        component={RenderInput}
                                    />
                                </GridItem>
                            </GridContainer>
                            <Grid container justify={'flex-end'}>
                                <Button type={'submit'}  color={'success'} size={'sm'} round>
                                    Save
                                </Button>
                            </Grid>
                        </CardBody>
                    </form>
                </Card>
            </GridItem>
        )
    }
}
const validate=formValues=>{
  const errors={};
  const {contactEmail}=formValues;

  // need to validate to strings to ensure it is a link

    if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(contactEmail) && contactEmail){
        errors.contactEmail='Please enter a valid email address';
    }

  return errors;
};


const Form = reduxForm({
    form:'socialLinks',
    validate,
    enableReinitialize:true
})(SocialLinksForm);


const mapStateToProps=(state)=>{
  const {facebook,instagram,youtube,twitter,contactEmail}=state.data.socialLinks;
  return {
      initialValues:{
          facebook,
          instagram,
          youtube,
          twitter,
          contactEmail
      }
  }
};

export default connect(mapStateToProps)(Form);