import React,{useEffect} from 'react';
import {connect}from 'react-redux';

import {updateHomePage} from "../../../Store/Actions/Admin/DataActions";
import PageComponent from "./PageComponent";
import PageHooks from "./PageHooks";


const HomePage=({data,updateHomePage})=>{

    const [state,dispatch,Validate]=PageHooks();

    useEffect(()=>{

        dispatch({
            type:'loadData',
            payload:data
        })


    },[data,dispatch])


    const handleSubmit=(data)=>{
        const err = Validate(data);
        if(!err){
            dispatch({type:'clearErrors'});
            dispatch({type:'loading'});
            updateHomePage(data,res=>{
                dispatch({type:'loading'});

                if(res.status===1){
                    dispatch({type:'successNotif',payload:'Home page has been updated successfully!'})
                }else{
                    dispatch({type:'errorNotif',payload:res.msg});
                }
            })
        }
    }

    return (
        <PageComponent
            pageName={'Home Page'}
            state={state}
            dispatch={dispatch}
            handleSubmit={handleSubmit}
        />
    )
}

const mapStateToProps=(state)=>{
    return {data:state.data.homePage};
}
export default connect(mapStateToProps,{updateHomePage})(HomePage);