import React,{useEffect} from 'react';
import {connect}from 'react-redux';
import {updateAboutMePage} from "../../../Store/Actions/Admin/DataActions";
import PageHooks from "./PageHooks";
import PageComponent from "./PageComponent";


const AboutMePage=({data, updateAboutMePage})=>{
    const [state,dispatch,Validate]=PageHooks();

    useEffect(()=>{

        dispatch({
            type:'loadData',
            payload:data
        })
    },[data,dispatch])



    const handleSubmit=(data)=>{
        const err = Validate(data);
        if(!err){
            dispatch({type:'clearErrors'});
            dispatch({type:'loading'});
            updateAboutMePage(data,res=>{
                dispatch({type:'loading'});

                if(res.status===1){
                    dispatch({type:'successNotif',payload:'About me page has been updates successfully!'})
                }else{
                    dispatch({type:'errorNotif',payload:res.msg});
                }
            })
        }
    }

    return (
        <PageComponent
            pageName={'About Me Page'}
            state={state}
            dispatch={dispatch}
            handleSubmit={handleSubmit}
        />
    )
}
const mapStateToProps=(state)=>{
    return {data:state.data.aboutMe};
}
export default connect(mapStateToProps,{updateAboutMePage})(AboutMePage);