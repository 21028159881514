import {database} from "../../../Firebase";
import {LOAD_DATA} from "../../types";
import API from "../Api";


export const loadData=(callback)=>dispatch=>{

    const ref = database.ref('data');
    let payload={};
    ref.on('value',snap=>{
        const data = snap.val();
        if(data!==null){
            const {aboutMe,homePage,disclaimerPage, socialLinks, categories}=data;
            payload={
                aboutMe:aboutMe!==undefined?
                    {
                        title:aboutMe.title!==undefined?aboutMe.title:'',
                        bannerTitle:aboutMe.bannerTitle!==undefined?aboutMe.bannerTitle:'',
                        bannerImage:aboutMe.bannerImage!==undefined?aboutMe.bannerImage:'',
                        content:aboutMe.content!==undefined?aboutMe.content:null,
                        lastEdited:aboutMe.lastEdited!==undefined?aboutMe.lastEdited:'',
                        filter:aboutMe.filter!==undefined?aboutMe.filter:false,
                        description:aboutMe.description!==undefined?aboutMe.description:''
                    }:
                    {
                        title:'',
                        bannerTitle:'',
                        bannerImage:'',
                        content:null,
                        lastEdited:''
                    },
                homePage:homePage!==undefined?homePage:{title:'',bannerTitle:'',bannerImage:'', lastEdited:'',description:''},
                disclaimerPage:disclaimerPage!==undefined?disclaimerPage:{title:'',bannerTitle:'',bannerImage:'', lastEdited:'',content:''},
                socialLinks:socialLinks!==undefined?{
                    facebook:socialLinks.facebook!==undefined?socialLinks.facebook:'',
                    instagram:socialLinks.instagram!==undefined?socialLinks.instagram:'',
                    youtube:socialLinks.youtube!==undefined?socialLinks.youtube:'',
                    twitter:socialLinks.twitter!==undefined?socialLinks.twitter:'',
                    contactEmail:socialLinks.contactEmail!==undefined?socialLinks.contactEmail:''
                }:{facebook:'', instagram:'', youtube:'', twitter:'', contactEmail:''},
                categories:categories!==undefined?categories:[]
            }
        }else{
            payload={
                aboutMe:{
                    title:'',
                    bannerTitle:'',
                    bannerImage:'',
                    content:null,
                    lastEdited:''
                },
                homePage:{
                    title:'',
                    bannerTitle:'',
                    bannerImage:'',
                    lastEdited:'',
                },
                disclaimerPage: {
                    title:'',
                    bannerTitle:'',
                    bannerImage:'',
                    lastEdited:'',
                    content:null
                },
                socialLinks: {
                    facebook:'',
                    instagram:'',
                    youtube:'',
                    twitter:'',
                    contactEmail:''
                },
                categories:[]
            }
        }

       dispatch({type:LOAD_DATA, payload});
        callback({status:1});

    });



};

export const updateAboutMePage=(data,callback)=>async dispatch=>{

    const response = await API.post('/aboutMePage',data);
    callback(response.data);
};

export const updateHomePage=(data,callback)=>async dispatch=>{
   const response = await API.post('/homePage',data);
   callback(response.data);

};

export const updateDisclaimerPage=(data,callback)=>async dispatch=>{
    const response =await API.post('/disclaimerPage',data);
    callback(response.data);
};

export const updateSocialLinks=(data,callback)=>async dispatch=>{

   const response = await API.post('/socialLinks',data);
   callback(response.data);
};

export const updateCategories=(data,callback)=>async dispatch=>{

    const response = await API.post('/categories',{categories:data});
    callback(response.data);

    /*
  const ref = database.ref('data');
  ref.update({
      categories:data
  })
      .then(()=>callback({status:1}))
      .catch(err=>callback({status:2,msg:err.message}))



     */

};