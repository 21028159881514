import React from "react";
import {connect}from 'react-redux';
import GridItem from "../../../../components/Grid/GridItem";
import Card from "../../../../components/Card/Card";
import CardHeader from "../../../../components/Card/CardHeader";
import CardBody from "../../../../components/Card/CardBody";
import {Grid} from "@material-ui/core";
import CustomInput from "../../../../components/CustomInput/CustomInput";
import {Add, Close} from "@material-ui/icons";
import Button from "../../../../components/CustomButtons/Button";
import GridContainer from "../../../../components/Grid/GridContainer";
import Danger from "../../../../components/Typography/Danger";
import ConfirmModal from "../../../../My Components/ConfrimModal";


class CatergoriesForm extends React.Component{

    state={
      newCat:'',
      confirmModal:false,
      categories:null,
      error:'',
      confirmDeleteModal:false,
      deleteCat:''
    };

    componentDidMount() {
        this.setState({categories:this.props.categories})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.categories===null && this.props.categories!==null){
            this.setState({categories:this.props.categories})
        }
    }

    handleDelete=()=>{
      const {deleteCat,categories}=this.state;

     const newCats = categories.filter(cat=>cat!==deleteCat?cat:null);


      this.setState({confirmDeleteModal:false,deleteCat:'',categories:newCats})

    };

    renderCategories=()=>{
       const {categories}=this.state;
       if(categories!== null){
          if(categories.length>0){
                return (
                    <div>
                        {categories.map(cat=>{
                           return (
                               <Grid key={cat}  alignItems="center" container justify={'space-between'} direction={'row'}>
                                   <h4>{cat}</h4>
                                   <Button onClick={()=>this.setState({confirmDeleteModal:true, deleteCat:cat})}  size={'sm'} justIcon color={'danger'} round>
                                       <Close/>
                                   </Button>
                               </Grid>

                           )
                        })}
                    </div>
                )
            }else{
                return (
                    <div>
                        <h4>No Categories added yet!</h4>
                    </div>
                )
            }
       }else{
           return (
               <div>
                   <h4>No Categories yet!</h4>
               </div>
           )
       }
    };

    handleChange=name=>event=>{
        this.setState({[name]:event.target.value})
    };

    AddCat=()=>{
      const {newCat, categories} = this.state;

        if(!categories.includes(newCat)){
           if(newCat.length>0){
                categories.push(newCat);
               this.setState({categories,error:'', newCat: ''})
           } else{
               this.setState({
                   error:'New category cannot be blank!'
               })
           }
        }else{
            this.setState({
                error:'Category already set!'
            })
        }


    };

    render(){
        const {newCat, categories,error, deleteCat, confirmDeleteModal}=this.state;
        const {classes}=this.props;
        return (
            <GridItem xs={12} sm={12} md={6}>
                <ConfirmModal
                    open={confirmDeleteModal}
                    close={()=>this.setState({confirmDeleteModal:false})}
                    Title={'Confirm Delete Category'}
                    classes={classes}
                    saveText={'Delete'}
                    saveColor={'danger'}
                    saveAction={this.handleDelete}
                    content={`Are you sure you want to delete ${deleteCat}`}
                />
                <Card>
                    <CardHeader color={'rose'}>
                        Categories
                    </CardHeader>
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12}>
                                <CustomInput
                                    id="newCat"
                                    inputProps={{
                                        placeholder: "Enter a new category here",
                                        onChange:(this.handleChange('newCat')),
                                        value:newCat
                                    }}
                                    formControlProps={{
                                        fullWidth:true
                                    }}

                                />
                                <Danger>{error}</Danger>
                                <Button type={'button'} block onClick={()=>this.AddCat()} color={'warning'} size={'sm'} round>
                                    <Add/>
                                    Add
                                </Button>
                            </GridItem>

                        </GridContainer>
                        <GridItem xs={12}>
                            {this.renderCategories()}
                        </GridItem>
                        <GridItem xs={12}>
                            <Button onClick={()=>this.props.onSubmit(categories)} size={'sm'} round color={'success'}>
                                Save
                            </Button>
                        </GridItem>
                    </CardBody>
                </Card>
            </GridItem>
        )
    }
}
const mapStateToProps=state=>{
    return{
        categories:state.data.categories
    }
};
export default connect(mapStateToProps)(CatergoriesForm);