import {LOAD_ALL_USERS,LOAD_ALL_NEWSLETTER} from "../../types";

const INITIAL_STATE={
    allUsers:null,
    newsletterUsers:null
}

export default (state=INITIAL_STATE,{type,payload})=>{
    switch(type){
        case LOAD_ALL_USERS:
            return {...state,allUsers:payload}
        case LOAD_ALL_NEWSLETTER:
            return {...state,newsletterUsers: payload}
        default:
            return {...state};
    }
}