import axios from "axios";

const overRide = true;

const baseURL = process.env.NODE_ENV==='production'?
    'https://us-central1-fir-d4dc1.cloudfunctions.net/AdminApi'
    :'http://localhost:5000/fir-d4dc1/us-central1/AdminApi'

const overrideUrl =  'https://us-central1-fir-d4dc1.cloudfunctions.net/AdminApi';

const API = axios.create({
    baseURL:overRide ? overrideUrl : baseURL
});

export default API;
