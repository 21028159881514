import {database,storage} from "../../../Firebase";
import {LOAD_IMAGES} from "../../types";
import imageCompression from "browser-image-compression";

export const getImages=(callback)=>dispatch=>{
    const ref = database.ref('images');
    ref.on('value',snap=>{

        const images = snap.val();
        dispatch({type:LOAD_IMAGES,payload:images});
        callback({status:1})

    })
};


export const uploadImage= (files,type,callback)=>async dispatch=>{
    // option types avatar, banner, blog
    const file = files[0];

    database.ref('images').once('value').then(async snap=>{
        const images = snap.val();

       const existingImage = await Object.keys(images).reduce((a,v)=>{

            const {name}=images[v];
            if(name === file.name){
                a=true
            }

            return a;
        },false);


        if(!existingImage){
            const options={
                maxSizeMB:0.5,
                maxWidthOrHeight:type==='avatar'?400:type==='banner'?1024:600,
                useWebWorker: true
            };
            // 370299
            // 500000

            console.log('options', options, type, file);
            let compressedFile = file
            if(file.size>500000){
                console.log('compressor ran');
                compressedFile= await imageCompression(file,options);
            }
            console.log('compressed file', compressedFile);
            const imageRef = database.ref('images');
            await storage.child(`images/${file.name}`).put(compressedFile).then(snap=>{

                snap.ref.getDownloadURL().then(url=>{

                    imageRef.push({
                        name:file.name,
                        url,
                        size:compressedFile.size,
                        type:type
                    }).then(()=>{
                        callback({status:1,url})
                    }).catch(err=>callback({status:2,msg:err.message}))
                })
            })
        }else{
            callback({status:2,msg:'Error - images already exists'})
        }


    });




};

export const deleteImage =(name,id,callback)=>async dispatch=>{

    const ref = storage.child(`images/${name}`);

    ref.delete().then(()=>{

        const dbRef = database.ref(`images/${id}`);
        dbRef.remove()
            .then(()=>callback({status:1}))
            .catch(err=>callback({status:2,msg:err.message}))
    }).catch(err=>callback({status:2,msg:err.message}));
};
