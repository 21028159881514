import {LOAD_ALL_COMMENTS, LOAD_BLOG_COMMENTS, UPDATE_UNREAD_COMMENTS} from "../../types";

const INITIAL_STATE={
    allComments:null,
    ViewComments:{
        blogTitle:null,
        blogId:null
    },
    unread:0
}

export default (state=INITIAL_STATE,{type,payload})=>{
    switch(type){
        case UPDATE_UNREAD_COMMENTS:
            return {...state,unread:payload}
        case LOAD_BLOG_COMMENTS:
            return {...state,ViewComments: payload}
        case LOAD_ALL_COMMENTS:
            return {...state,allComments:payload}
        default:
            return {...state};
    }

}