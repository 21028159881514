export const ADMIN_LOGIN='adminLogin';
export const ADMIN_LOGOUT='adminLogout';
export const ADMIN_UPDATE_DETAILS='adminUpdateDetails';
export const ADMIN_UPDATE_IMAGE='adminUpdateImage';
// images
export const LOAD_IMAGES='loadImages';

// data
export const LOAD_DATA="loadData";
export const UPDATE_ABOUT_ME="updateAboutMe";
export const LOAD_SETTINGS='load_settings';
export const LOAD_BLOGS='load_Blogs';
export const EDIT_BLOG='EditBlog';
export const VIEW_BLOG='viewBlog';
export const TOKEN_KEY='React-bloggerAdmin';
// users
export const LOAD_ALL_USERS='loadAllUsers';
export const LOAD_ALL_NEWSLETTER='loadAllNewsLetter';
//comments
export const LOAD_ALL_COMMENTS='loadAllComments';
export const LOAD_BLOG_COMMENTS='loadBlogComments';
export const UPDATE_UNREAD_COMMENTS='updateUnReadComments';
//dashboard
export const LOAD_DASHBOARD_DATA='loadDashData';

