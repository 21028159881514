import React from "react";
import {Grid, TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Save,Edit, AddAlarm} from "@material-ui/icons";

// components
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import {Loading} from "../../../components/Loading";
import Danger from "../../../components/Typography/Danger";
import ImageModal from "../../../My Components/ImageModal";
import CardFooter from "../../../components/Card/CardFooter";
import Button from '../../../components/CustomButtons/Button';
import pageComponentStyle from "../../../assets/jss/material-dashboard-react/customStyles/pageComponentStyle";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import {MainSwitch} from "../../../components/Switch";
import WYSIWYG from "../../../My Components/WYSIWYG";
import Snackbar from "../../../components/Snackbar/Snackbar";

const useStyles = makeStyles(pageComponentStyle);

const PageComponent=(props)=>{

    const classes = useStyles();

    const {pageName,state,dispatch,handleSubmit}=props;
    const {
        loading,
        error,
        imageModal,
        image,
        filter,
        title,
        titleError,
        bannerTitle,
        bannerTitleError,
        description,
        descriptionError,
        content,
        contentError,
        lastEdited,
        notif,
        notifMsg,
        notifType
    }=state;

    const onSubmit=()=>{
        const data = {bannerImage:image,filter,title,bannerTitle,description}

        if(pageName!=='Home Page'){
            data['content']=content;
        }
        handleSubmit(data);
    }

    return (
        <div>
            <Snackbar
                close
                closeNotification={()=>dispatch({type:'closeNotif'})}
                open={notif}
                message={notifMsg}
                icon={AddAlarm}
                color={notifType}
                place={'br'}
            />
            <ImageModal
                show={imageModal}
                close={()=>dispatch({type:'imageModal'})}
                mode={'banner'}
                onSave={url=>dispatch({type:'imageSave',payload:url})}
                selectedImage={image}
            />
            <Loading show={loading}/>
                <Card>
                    <CardHeader color={'rose'} className={classes.header}>
                        {pageName}
                        <Button
                            size={'sm'}
                            color={'success'}
                            onClick={onSubmit}
                        >
                            <Save/>
                            Save
                        </Button>
                    </CardHeader>
                    <CardBody>
                        <Danger>{error}</Danger>
                        <GridContainer>
                            <GridItem xs={6}>
                                <TextField
                                    className={classes.textInput}
                                    label={'Title'}
                                    value={title}
                                    placeholder={'Page Title'}
                                    error={!!titleError}
                                    fullWidth
                                    helperText={titleError}
                                    id={'title'}
                                    onChange={e=>dispatch({type:'title',payload:e.target.value})}
                                />
                            </GridItem>
                            <GridItem xs={6}>
                                <TextField
                                    className={classes.textInput}
                                    label={'Banner Title'}
                                    value={bannerTitle}
                                    placeholder={'Banner Title'}
                                    error={!!bannerTitleError}
                                    fullWidth
                                    helperText={bannerTitleError}
                                    id={'bannerTitle'}
                                    onChange={e=>dispatch({type:'bannerTitle',payload:e.target.value})}
                                />
                            </GridItem>
                            <GridItem xs={12}>
                                <TextField
                                    className={classes.textInput}
                                    label={'Page Description'}
                                    value={description}
                                    placeholder={'page description for seo'}
                                    error={!!descriptionError}
                                    multiline
                                    rows={4}
                                    fullWidth
                                    helperText={descriptionError}
                                    id={'bannerTitle'}
                                    onChange={e=>dispatch({type:'description',payload:e.target.value})}
                                />
                            </GridItem>
                        </GridContainer>
                        <MainSwitch
                            label={'Filter Banner'}
                            checked={filter}
                            value={'filter'}
                            onChange={()=>dispatch({type:'filter'})}
                        />
                        <GridContainer justify={'space-between'}>
                            <p>Recommended image size is 1024 width X 600 height for banner images</p>
                            <Button onClick={()=>dispatch({type:'imageModal'})}  size={'sm'} round><Edit/>Edit Image</Button>
                        </GridContainer>

                        {image!=='' && image?
                            <Grid container alignItems={'center'} justify={'center'}>
                                <img alt={'banner image'+title} src={image} style={{maxHeight:200,maxWidth:'100%'}}/>
                            </Grid>:
                            <Grid container alignItems={'center'} justify={'center'} >
                                <div style={{textAlign:'center',backgroundColor:'lightGrey', height:200,width:'100%'}}>
                                    <h5 >No Image</h5>
                                </div>
                            </Grid>
                        }
                        <p>Last Edited: {lastEdited!==''?new Date(lastEdited).toString():'No Last Edit'}</p>
                        {pageName!=='Home Page'?
                            <WYSIWYG
                                content={content}
                                onChange={(c)=>dispatch({type:'content',payload:c})}
                            />:null
                        }
                        {contentError??<Danger>{contentError}</Danger>}
                    </CardBody>
                    <CardFooter >
                        <Button size={'sm'} color={'success'} onClick={onSubmit}>
                            <Save/>
                            Save
                        </Button>
                    </CardFooter>
                </Card>
        </div>

    )
}



export default PageComponent;