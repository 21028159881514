export default {
    header:{
        display:'flex',
        justifyContent:'space-between',
        paddingLeft:10,
        paddingRight:10,
        flexDirection:'row',
        alignItems:'center'
    },
    textInput:{
        marginBottom:15
    }
}