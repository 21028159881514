import {LOAD_BLOGS, EDIT_BLOG, VIEW_BLOG} from "../../types";

const initialState={
  data:null,
  loaded:false,
  editBlog:null,
  viewBlog:null
};

export default (state=initialState,action)=>{
    switch (action.type) {
        case LOAD_BLOGS:
            return {...state,data:action.payload,loaded:true};
        case EDIT_BLOG:
            return {...state, editBlog:action.payload };
        case VIEW_BLOG:
            return {...state,viewBlog: action.payload};
        default :
            return {...state};
    }
}