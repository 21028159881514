import {LOAD_IMAGES} from "../../types";

const initialState={
    images:null,
    loaded:false
};

export default(state=initialState,action)=>{
    if(action.type===LOAD_IMAGES){
        return {...state,images:action.payload,loaded:true}
    }else{
        return {...state};
    }
}