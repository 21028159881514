import React from "react";
import { connect} from 'react-redux';
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import BlogComponent from "./Components/BlogComponent";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import {AddBlog} from "../../../Store/Actions/Admin/BlogActions";
import history from "../../../history";
import {Loading} from "../../../components/Loading";
import Snackbar from "../../../components/Snackbar/Snackbar";
import {AddAlarm, Backspace} from "@material-ui/icons";
import Button from "../../../components/CustomButtons/Button";
import {Grid} from "@material-ui/core";

class NewBlog extends React.Component{

    state={
        loading:false,
        notif:false,
        notifMsg:''
    };

    handleSubmit=(data)=>{
        this.setState({loading:true});

        this.props.AddBlog(data,res=>{
            res.status===1?
                history.push('/admin/blog'):
                this.setState({
                    loading:false,
                    notif:true,
                    notifMsg:res.msg
                })
        })

    };


    render(){
        const {loading,notif,notifMsg}=this.state;
        return (
            <div>
                <Loading
                    show={loading}
                />
                <Snackbar
                    icon={AddAlarm}
                    place={'br'}
                    color={'warning'}
                    open={notif}
                    close
                    closeNotification={()=>this.setState({notif: false, notifMsg: ''})}
                    message={notifMsg}
                />
                <GridContainer>
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader color={'rose'}>
                                <Grid container justify={'space-between'} direction={'row'} alignItems={'center'}>
                                    <h4>New Blog Post</h4>
                                    <Button onClick={()=>history.push('/admin/blog')} size={'sm'} round>
                                        <Backspace/>
                                        Back to Blog Posts
                                    </Button>
                                </Grid>
                            </CardHeader>
                            <CardBody>
                                <BlogComponent
                                    onSubmit={this.handleSubmit}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>

        )
    }
}




export default connect(null, {AddBlog})(NewBlog);