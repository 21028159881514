import React, {useState,useEffect} from "react";
import {connect}from 'react-redux';
import {makeStyles} from "@material-ui/core/styles";
import {CSVLink} from "react-csv";

// components
import Card from "../../../components/Card/Card";
import CardBody from "../../../components/Card/CardBody";
import CardHeader from "../../../components/Card/CardHeader";
import AllUsersStyle from "../../../assets/jss/material-dashboard-react/customStyles/AllUsersStyle";
import {getNewsletterUsers} from '../../../Store/Actions/Admin/UserActions';
import Danger from "../../../components/Typography/Danger";
import Table from '../../../components/Table/Table';
import {FormatDate} from "../../../Functions/FormateDate";
import Success from "../../../components/Typography/Success";

const useStyles = makeStyles(AllUsersStyle)
const NewsletterUsers=({users,getNewsletterUsers})=>{
    const classes = useStyles();
    const [error,setError]=useState(null);

    useEffect(()=>{
        getNewsletterUsers(res=>{
            if(res.status===2){
                setError(res.msg)
            }
        });
    },[])



    const tableHead = ['Name','Email','Created','Subscribed'];
    const tableData =(users,table)=>{
        if(users){
            return users.reduce((a,v)=>{
                const {email,name,created,subscribed}=v;
                a.push([
                    name,
                    email,
                    FormatDate(created),
                    table?subscribed?<Success>True</Success>:<Danger>False</Danger>:subscribed
                ])
                return a;
            },[]);
        }else{return []}

    }

    const renderUsers=()=>{
        if(users){

            return (
                <Table
                    tableHeaderColor={'primary'}
                    tableData={tableData(users,true)}
                    tableHead={tableHead}
                />
            )
        }
    }



    return (
        <Card>
            <CardHeader color={'primary'} className={classes.cardHeader}>
                All Users
                <CSVLink
                    filename={'my-pale-legs-newsletter-users-'+new Date()+'.csv'}
                    className={classes.downloadButton}
                    headers={tableHead}
                    data={tableData(users)}
                    target="_blank"
                >Download</CSVLink>
            </CardHeader>
            <CardBody>
                {error??<Danger>{error}</Danger>}
                {renderUsers()}
            </CardBody>
        </Card>
    )

}

const mapStateToProps=(state)=>{
    return {users:state.users.newsletterUsers}
}

export default connect(mapStateToProps,{getNewsletterUsers})(NewsletterUsers)