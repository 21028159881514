
const Validation=(data)=>{
    let errors=null;
    const keys = Object.keys(data);
    keys.forEach(key=>{
        if(data[key].length<=0){
            if(errors){
                errors[key]='Please check '+key
            }else{
                errors={key:'Please check '+key}
            }

        }
    });

    return errors;
}

export default Validation;