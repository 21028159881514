import {UPDATE_ABOUT_ME,LOAD_DATA} from "../../types";


const initialState={
  loaded:false,
  aboutMe:{
      title:'',
      bannerTitle:'',
      bannerImage:'',
      content:null,
      lastEdited:'',
      filter:false,
      description: ''
  },
  homePage:{
      title:'',
      bannerTitle:'',
      bannerImage:'',
      lastEdited:'',
      filter:false,
      description:''
  },
  disclaimerPage: {
      title:'',
      bannerTitle:'',
      bannerImage:'',
      lastEdited:'',
      content:null,
      filter:false,
      description:''
  },
    socialLinks:{
      facebook:'',
      instagram:'',
      youtube:'',
      twitter:'',
      contactEmail:''
    },
    categories:null

};


export default (state=initialState,action)=>{

    switch(action.type){
        case LOAD_DATA:
            return {
                ...state,
                loaded:true,
                aboutMe:action.payload.aboutMe,
                homePage:action.payload.homePage,
                disclaimerPage: action.payload.disclaimerPage,
                categories: action.payload.categories,
                socialLinks: action.payload.socialLinks
            };
        case UPDATE_ABOUT_ME:
            return {
                ...state,
                aboutMe:action.payload.aboutMe
            };
        default:
            return {...state};
    }


};