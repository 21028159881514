import API from "../Api";
import {LOAD_ALL_USERS,LOAD_ALL_NEWSLETTER} from "../../types";

export const getAllUsers=(callback)=>async dispatch=>{

    const response = await API.get('/allUsers');
    if(response.data.status===1){
        dispatch({type:LOAD_ALL_USERS,payload:response.data.data});
    }else{
        callback({status:2,msg:response.data.msg})
    }
}

export const getNewsletterUsers=(callback)=>async dispatch=>{
    const res = await API.get('/newsletter');
    if(res.data.status===1){
        dispatch({type:LOAD_ALL_NEWSLETTER,payload:res.data.data});
    }else{
        callback({status:2,msg:res.data.msg})
    }
}