import React from "react";
import {connect}from 'react-redux';
import Card from "../../../components/Card/Card";
import CardBody from "../../../components/Card/CardBody";
import CardHeader from "../../../components/Card/CardHeader";
import {Grid} from "@material-ui/core";
import history from "../../../history";
import {Backspace} from "@material-ui/icons";
import Button from "../../../components/CustomButtons/Button";
import {FormatDate} from "../../../Functions/FormateDate";
import Table from "../../../components/Table/Table";

class BlogViews extends React.Component{

    renderTable=()=>{
        const views = this.props.blog.views;
        if(views && views!==0){
            const keys=Object.keys(views);
            if(keys.length>0){

                const tableData=keys.reduce((a,v)=>{
                    a.push([v,FormatDate(views[v])]);
                    return a;
                },[]);

                const TableHead=['Ip Address','Time Viewed'];

                return (
                    <Table
                        tableHeaderColor={'rose'}
                        tableData={tableData}
                        tableHead={TableHead}
                    />
                )



            }else{
                return (
                    <div>
                        <h3>No Views on this post yet!</h3>
                    </div>
                )
            }
        }else{
            return (
                <div>
                    <h3>No Views on this post yet!</h3>
                </div>
            )
        }
    };



    render(){
        const {title}=this.props.blog;
        return (
            <Grid>
                <Button onClick={()=>history.push('/admin/blog')} size={'sm'} round>
                    <Backspace/>
                    Back to Blog Posts
                </Button>
                <Card>
                    <CardHeader color={'rose'}>
                        <Grid container justify={'space-between'} alignItems={'center'} direction={'row'}>
                            <h4>{title}</h4>
                            <h5>Views of post</h5>
                        </Grid>
                    </CardHeader>
                    <CardBody>
                        {this.renderTable()}
                    </CardBody>
                </Card>
            </Grid>
        )
    }

}
const mapStateToProps=state=>{
    return {
        blog:state.blogs.viewBlog,
        auth:state.auth,
    }
};

export default connect(mapStateToProps)(BlogViews)