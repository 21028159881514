import {LOAD_SETTINGS} from "../../types";
import {database} from "../../../Firebase";

export const getSettings=(callback)=>dispatch=>{

    let settings={
        primaryColor:'',
        secondaryColor:'',
        tertiaryColor:'',
        primaryFont:'',
        secondaryFont:'',
    };

    const ref = database.ref('settings');

    ref.on('value',snap=>{

        const data=snap.val();

        if(data!== undefined && data !== null){

            const {primaryColor, secondaryColor, tertiaryColor, primaryFont, secondaryFont}=data;

            settings={
                primaryColor:primaryColor!==undefined?primaryColor:'',
                secondaryColor:secondaryColor!==undefined?secondaryColor:'',
                tertiaryColor:tertiaryColor!== undefined?tertiaryColor:'',
                primaryFont: primaryFont!== undefined?primaryFont:'',
                secondaryFont:secondaryFont!==undefined?secondaryFont:'',
            };

            dispatch({type:LOAD_SETTINGS,payload:settings});
            callback({status:1})

        }else{
            dispatch({type:LOAD_SETTINGS,payload:settings});
            callback({status:1})
        }

    });


};

export const saveColors=(colors,callback)=>dispatch=>{
    const {primary,secondary,tertiary}=colors;

  const ref = database.ref('settings');
  ref.update({
      primaryColor:primary,
      secondaryColor:secondary,
      tertiaryColor:tertiary
  })
      .then(()=>callback({status:1}))
      .catch(err=>callback({status:2,msg:err.message}))
};

export const SaveFonts=(data,callback)=>dispatch=>{
  const {primaryFont,secondaryFont}=data;

  const ref = database.ref('settings');

  ref.update({
      primaryFont,
      secondaryFont,
  })
      .then(()=>callback({status:1}))
      .catch((err)=>callback({status:2,msg:err.message}))

};

export const SaveTest=(data,callback)=>dispatch=>{





};