import {Dashboard, Person, ViewCompact,Settings, PhotoLibrary,AccountBox,Message, MenuBook} from "@material-ui/icons";
// components Page
import DashboardPage from './Dashboard/DashboardPage';
import ProfilePage from "./Profile/ProfilePage";
import BlogPage from "./Blog/blogPage";
import GalleryPage from "./Gallery/GalleryPage";
import SettingsPage from "./Settings/SettingsPage";
import HomePage from "./Pages/HomePage";
import AboutMePage from "./Pages/AboutMePage";
import DisclaimerPage from "./Pages/DisclaimerPage";
import AllUsers from "./Users/AllUsers";
import NewsletterUsers from "./Users/NewsletterUsers";
import AllComments from "./Comments/AllComments";

const dashboardRoutes=[
    {
        path: "/home",
        name: "Dashboard",
        Icon: Dashboard,
        component: DashboardPage,
        layout: "/admin"
    },
    {
        path:'/profile',
        name:'Profile',
        Icon:Person,
        component:ProfilePage ,
        layout:"/admin"
    },
    {
        path:'/pages',
        name:'Pages',
        Icon:ViewCompact,
        layout:"/admin",
        items:[
            {
                name:'Home',
                path:'/home',
                component:HomePage
            },
            {
                name:'About Me',
                path:'/aboutme',
                component:AboutMePage
            },
            {
                name:'Discliamer',
                path:'/disclaimer',
                component:DisclaimerPage
            }
        ]
    },
    {
        path:'/blog',
        name:'Blog',
        Icon:MenuBook,
        component:BlogPage ,
        layout:"/admin"
    },
    {
        path:'/comments',
        name:'Comments',
        Icon: Message,
        component: AllComments,
        layout:'/admin'
    },
    {
        path: '/users',
        name:'Users',
        Icon:  AccountBox,
        layout:'/admin',
        items:[
            {
                name:'All Users',
                path:'/allusers',
                component:AllUsers
            },
            {
                name:'Newsletter',
                path:'/newsletter',
                component:NewsletterUsers
            }
        ]
    },
    {
        path:'/gallery',
        name:'Gallery',
        Icon:PhotoLibrary,
        component:GalleryPage,
        layout:'/admin'
    },
    {
        path:'/settings',
        name:'Settings',
        Icon:Settings,
        component:SettingsPage,
        layout:"/admin"
    },
];

export default dashboardRoutes;