import {LOAD_DASHBOARD_DATA} from "../../types";

const INITIAL_STATE={
    pageData:null,
    devices:null,
    visitorTypes:null,
    sessions:null
}

export default (state=INITIAL_STATE,{type,payload})=>{
    switch(type){
        case LOAD_DASHBOARD_DATA:
            return payload
        default:
            return {...state};
    }
}