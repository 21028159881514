import {combineReducers} from "redux";
import {reducer as formReducer}from 'redux-form';
import Auth from './Admin/AdminAuth';
import Images from './Admin/ImagesReducer';
import Data from './Admin/DataReducer';
import Settings from './Admin/SettingsReducer';
import Blogs from './Admin/BlogReducer';
import Users from './Admin/UsersReducer';
import Comments from './Admin/CommentsReducer';
import dashboard from './Admin/dashboardRedcuer';
const index={
    form:formReducer,
    auth:Auth,
    images:Images,
    data:Data,
    settings:Settings,
    blogs:Blogs,
    users:Users,
    comments:Comments,
    dashboard
};

export default combineReducers(index);