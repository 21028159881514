export default {
    cardHeader:{
        display:'flex',
        justifyContent:'space-between',
        paddingLeft:10,
        paddingRight:10,
        flexDirection:'row',
        alignItems:'center'
    },
    downloadButton:{
        color:'white',
        '&:hover':{
            fontWeight:'bold',
            color:'white'
        }
    }
}