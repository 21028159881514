import {database} from "../../../Firebase";
import {LOAD_ALL_COMMENTS, LOAD_BLOG_COMMENTS, UPDATE_UNREAD_COMMENTS} from "../../types";

export const GetAllComment=(callback)=>(dispatch)=>{
    const ref = database.ref('comments');
    ref.on('value',snap=>{
        const values = snap.val();
        if(values){
            dispatch({type:LOAD_ALL_COMMENTS,payload:values})
        }
    });
}

export const UnSubscribeComment=(callback)=>dispatch=>{
   return database.ref('comments').off();
    //callback();
}

export const LoadBlogComment=(data,callback)=>dispatch=>{
    dispatch({type:LOAD_BLOG_COMMENTS,payload:data});
    callback();
}

export const PostComment=(blogId,comment,callback)=>(dispatch,getState)=>{

    const {displayName,uid,photoURL}=getState().auth;

    const ref = database.ref(`comments/${blogId}`);
    ref.push({
        author: displayName+' - Author',
        authorId:uid,
        avatar:photoURL,
        date:Date.now(),
        hidden:false,
        read:true,
        text:comment
    })
        .then(()=>callback({status:1}))
        .catch((err)=>callback({status:2,msg:err.message}))
}
export const HideComment=(blogId,commentId,state,callback)=>dispatch=>{
    database.ref(`comments/${blogId}/${commentId}`).update({hidden:state})
        .then(()=>callback({status:1}))
        .catch((e)=>callback({status:2,msg:e.message}))
}
export const DeleteComment=(blogId,commentId,callback)=>dispatch=>{
    database.ref(`comments/${blogId}/${commentId}`).remove()
        .then(()=>callback({status:1}))
        .catch(e=>callback({status:2,msg:e.message}))
}
export const MarkAsRead=(blogId,commentId)=>async dispatch=>{
    await database.ref(`comments/${blogId}/${commentId}`).update({read:true})
}

export const SubscribeToUnRead=()=>dispatch=>{
    database.ref('comments').on('value',snap=>{
        const values=snap.val();
        if(values){
           const unRead= Object.keys(values).reduce((a,v)=>{

               const comments=values[v];
              a+= Object.keys(comments).reduce((a2,v2)=>{

                  if(!comments[v2].read){
                      a2+=1
                  }
                   return a2
               },0)


                return a
            },0);

            dispatch({type:UPDATE_UNREAD_COMMENTS,payload:unRead});
        }
    })
}