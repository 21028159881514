import React,{useEffect} from "react";
import {connect}from 'react-redux';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";
// @material-ui/icons
import {ExitToApp, Notifications} from "@material-ui/icons";
// core components
import {AdminLogout} from "../../Store/Actions/Admin/ProfileActions";
import Button from "../CustomButtons/Button.js";
import history from "../../history";
import styles from "../../assets/jss/material-dashboard-react/components/headerLinksStyle.js";
import {UnSubscribeComment,SubscribeToUnRead} from "../../Store/Actions/Admin/CommentsActions";

const useStyles = makeStyles(styles);

function AdminNavbarLinks({AdminLogout,UnSubscribeComment,SubscribeToUnRead,unread}) {
  const classes = useStyles();

  useEffect(()=>{

      SubscribeToUnRead();

      return ()=>UnSubscribeComment();

  },[])

  const handleLogOut=()=>{
      AdminLogout(res=>{
          if(res.status===1){
              history.push('/');
          }
      })
  }

  const handleClickNotification=()=>{
      history.push('/admin/comments')
  }

  return (
    <div>
      <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-haspopup="true"
          onClick={handleClickNotification}
          className={classes.buttonLink}
      >
          <Notifications className={classes.icons} />
          <span className={classes.notifications}>{unread}</span>
          <Hidden mdUp implementation="css">
              <p className={classes.linkText}>
                  Notification
              </p>
          </Hidden>
      </Button>
      <Button
        color={window.innerWidth > 959 ? "transparent" : "white"}
        justIcon={window.innerWidth > 959}
        simple={!(window.innerWidth > 959)}
        aria-label="Dashboard"
        className={classes.buttonLink}
        onClick={handleLogOut}
      >
        <ExitToApp className={classes.icons} />
        <Hidden mdUp implementation="css">
          <p className={classes.linkText}>Logout</p>
        </Hidden>
      </Button>
    </div>
  );
}
const mapStateToProps=(state)=>{
    return {unread:state.comments.unread};
}
export default connect(mapStateToProps,{AdminLogout, SubscribeToUnRead,UnSubscribeComment})(AdminNavbarLinks);