import React,{useEffect,useState} from "react";
import {connect}from 'react-redux';
import {makeStyles} from "@material-ui/core/styles";
import {CSVLink} from "react-csv";
// components
import Card from "../../../components/Card/Card";
import CardBody from "../../../components/Card/CardBody";
import CardHeader from "../../../components/Card/CardHeader";
import AllUsersStyle from "../../../assets/jss/material-dashboard-react/customStyles/AllUsersStyle";
import {getAllUsers} from '../../../Store/Actions/Admin/UserActions';
import Danger from "../../../components/Typography/Danger";
import Table from '../../../components/Table/Table';

const useStyles = makeStyles(AllUsersStyle)
const AllUsers =({getAllUsers,users})=>{

    const classes = useStyles();
    const [error,setError]=useState(null);

    useEffect(()=>{
        getAllUsers(res=>{
            if(res.status===2){
                setError(res.msg)
            }
        });
    },[]);

    const tableData =(users)=>{
        if(users){
            return users.reduce((a,v)=>{
                const {email,displayName,created,lastLogin}=v;
                a.push([displayName,email,created,lastLogin])
                return a;
            },[]);
        }else{return []}

    }
    const tableHead = ['Name','Email','Created','Last Login'];

    const renderUsers=()=>{
        if(users){


            return (
                <Table
                    tableHeaderColor={'info'}
                    tableData={tableData(users)}
                    tableHead={tableHead}
                />
            )
        }
    }


    return (
        <Card>
            <CardHeader color={'info'} className={classes.cardHeader}>
                All Users
                <CSVLink
                    filename={'my-pale-legs-all-users-'+new Date()+'.csv'}
                    className={classes.downloadButton}
                    headers={tableHead}
                    data={tableData(users)}
                    target="_blank"
                >Download</CSVLink>
            </CardHeader>
            <CardBody>
                {error??<Danger>{error}</Danger>}
                {renderUsers()}
            </CardBody>
        </Card>
    )


}
const mapStateToProps=(state)=>{
    return {
        users:state.users.allUsers
    }
}

export default connect(mapStateToProps,{getAllUsers})(AllUsers);