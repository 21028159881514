import React from "react";
import {connect}from 'react-redux';
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import {Grid, InputAdornment, Tooltip} from "@material-ui/core";
import Button from "../../../components/CustomButtons/Button";
import {Add, AddAlarm, Search, DeleteForever, RemoveRedEye, Edit, List} from '@material-ui/icons';
import history from "../../../history";
import CardBody from "../../../components/Card/CardBody";
import CustomInput from "../../../components/CustomInput/CustomInput";
import {Loading} from "../../../components/Loading";
import Snackbar from "../../../components/Snackbar/Snackbar";
import ConfirmModal from "../../../My Components/ConfrimModal";
import Table from "../../../components/Table/Table";
import Success from "../../../components/Typography/Success";
import Danger from "../../../components/Typography/Danger";
import {FormatDate} from "../../../Functions/FormateDate";
import {DeleteBLog, SetBlogEdit, SetBlogView} from "../../../Store/Actions/Admin/BlogActions";

class BlogPage extends React.Component{

    state={
      search:'',
      notif:false,
      notifMsg:'',
      notifType:'warning',
      loading:false,
      confirmModal:false,
      deleteBlogId:null
    };

    handleChange=name=>event=>{
        this.setState({[name]:event.target.value})
    };


    renderBlogs=()=>{
        const {blogs}=this.props;
        if(blogs){

            const TableData = blogs.reduce((a,v,index)=>{

            const {title,id,lastEdited,date,publish,views}=v;
           a.push([
               title,
               FormatDate(lastEdited),
               FormatDate(date),
               publish?<Success>True</Success>:<Danger>False</Danger>,
               views!==undefined?Object.keys(views).length:0,
               (
                   <div>
                       <Tooltip title={'Views'}>
                           <Button onClick={()=>this.openViews(v)} color={'rose'} justIcon round size={'sm'}>
                            <List/>
                           </Button>
                       </Tooltip>
                       <Tooltip title={'Delete Blog'}>
                           <Button onClick={()=>this.setState({confirmModal:true,deleteBlogId:id})} color={'danger'} justIcon round size={'sm'}>
                               <DeleteForever/>
                           </Button>
                       </Tooltip>
                       <Tooltip title={'View Blog'}>
                           <Button onClick={()=>this.openViewBlog(v)} color={'primary'} justIcon round size={'sm'}>
                               <RemoveRedEye/>
                           </Button>
                       </Tooltip>
                       <Tooltip title={'Edit Blog'}>
                           <Button onClick={()=>this.openEditBlog(v)}  color={'warning'} justIcon round size={'sm'}>
                               <Edit/>
                           </Button>
                       </Tooltip>
                   </div>
               )
           ]);

            return a;
        },[]);

       const tableHead = ['Title','Edited','Released','Published','Views','Actions'];

       return (
           <Table
            tableHead={tableHead}
            tableHeaderColor={'primary'}
            tableData={TableData}
           />
       )



        }else{
            return(
                <Grid container justify={'center'}>
                    <h5>No Blogs have been creted yet!</h5>
                </Grid>
            )
        }

    };

    confirmDelete=()=>{
        const {deleteBlogId}=this.state;
        this.setState({loading:true});
        this.props.DeleteBLog(deleteBlogId,res=>{
           res.status===1?
           this.setState({
               loading:false,
               notif:true,
               notifMsg:'Post Deleted Successfully',
               notifType:'success',
               deleteBlogId: null,
               confirmModal:false
           }):
           this.setState({
               loading:false,
               notif:true,
               notifMsg:res.msg,
               notifType:'danger'
           })

        })
    };

    openEditBlog=(blog)=>{
        this.props.SetBlogEdit(blog,res=>{
            history.push('/admin/blog/edit');
        })
    };

    openViewBlog=(blog)=>{
      this.props.SetBlogView(blog,res=>{
          history.push('/admin/blog/view')
      })
    };

    openViews=(blog)=>{
      this.props.SetBlogView(blog,res=>{
          history.push('/admin/blog/views');
      })
    };

    render(){
        const {loading, notif,notifMsg,notifType, confirmModal}=this.state;
        return (
            <div>
                <Loading
                    show={loading}
                />
                <Snackbar
                    icon={AddAlarm}
                    place={'br'}
                    color={notifType}
                    open={notif}
                    close
                    closeNotification={()=>this.setState({notif: false, notifType: 'warning',notifMsg: ''})}
                    message={notifMsg}
                />
                <ConfirmModal
                    open={confirmModal}
                    close={()=>this.setState({confirmModal: false})}
                    Title={'Confirm delete image'}
                    saveText={'Delete'}
                    saveColor={'danger'}
                    content={'Are you sure you want to delete this blog ?'}
                    saveAction={this.confirmDelete}
                />
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color={'rose'}>
                                <Grid container justify={'space-between'} direction={'row'} alignItems={'center'}>
                                    Blog Posts
                                    <Button size={'sm'} round onClick={()=>history.push('/admin/blog/new')}>
                                        <Add/>
                                        Create Blog
                                    </Button>
                                </Grid>
                            </CardHeader>
                            <CardBody>
                                <Grid container direction={'row'} justify={'flex-end'}>
                                    <CustomInput
                                        id="regular"
                                        inputProps={{
                                            placeholder: "Search",
                                            onChange:(this.handleChange('search')),
                                            endAdornment:(
                                                <InputAdornment position={'end'}>
                                                    <Search/>
                                                </InputAdornment>
                                            )
                                        }}
                                        formControlProps={{
                                            fullWidth: false
                                        }}
                                    />
                                </Grid>
                                {this.renderBlogs()}
                            </CardBody>
                        </Card>


                    </GridItem>
                </GridContainer>
            </div>

        )
    }
}
const mapStateToProps=state=>{
  return {blogs:state.blogs.data}
};

export default connect(mapStateToProps, {SetBlogEdit, SetBlogView, DeleteBLog})(BlogPage);