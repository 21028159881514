import React from "react";
import { connect} from 'react-redux';
import Interweave from 'interweave';
import Card from "../../../components/Card/Card";
import CardBody from "../../../components/Card/CardBody";
import {Avatar, Grid} from "@material-ui/core";
import Button from "../../../components/CustomButtons/Button";
import history from "../../../history";
import {Backspace} from "@material-ui/icons";
import {FormatDate} from "../../../Functions/FormateDate";
import {AddComment, AddReply} from "../../../Store/Actions/Admin/BlogActions";
import {Loading} from "../../../components/Loading";
import Carousel from "react-slick";

// import css sheets
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../assets/css/BlogImage.css";

class ViewBlog extends React.Component{

    state={
        loading:false
    };

    renderImages=()=>{
        const images =this.props.blog.images;
        const settings = {
            dots: true,
            infinite: true,
            speed: 600,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true
        };

        return (
            <div style={{marginLeft:'auto',marginRight:'auto'}}>
                <Carousel {...settings}>
                    {images.map((image,index)=>(
                        <div key={index}>
                            <div style={{
                                backgroundImage:`url("${image}")`,
                                height:400,
                                width:'100%',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                backgroundSize:'150%',
                                backgroundRepeat:'no-repeat',
                                backgroundColor:'rgba(0,0,0,0.3)',
                                backgroundPosition:'center bottom',
                                backgroundBlendMode:'multiply',
                                backdropFilter:'blur(10px)'
                            }}>
                                <img
                                    key={index}
                                    src={image}
                                    alt={index+'image'}
                                    className="imageBlock"
                                />
                            </div>
                        </div>

                    ))}
                </Carousel>
            </div>

        )
    };

    handleMainComment=(text)=>{

        const {uid,displayName,photoURL}=this.props.auth;
        const data={
            uid,
            author:displayName,
            avatarUrl:photoURL,
            text
        };

        this.setState({loading:true});

        this.props.AddComment(this.props.blog.id,data,res=>{
           this.setState({loading:false})
        });

    };

    handleReplyComment=(text,commentId)=>{
        const {uid,displayName,photoURL}=this.props.auth;
        const data={
            uid,
            author:displayName,
            avatarUrl:photoURL,
            text
        };

        this.setState({loading:true});

        this.props.AddReply(this.props.blog.id,commentId,data,res=>{
            this.setState({loading:false})
        })

    };

    render(){
        const {author,title,content,publish,date, lastEdited}=this.props.blog;
        const {photoURL}=this.props.auth;
        return (
            <Grid>
                <Loading
                    show={this.state.loading}
                />
                <Button onClick={()=>history.push('/admin/blog')} size={'sm'} round>
                    <Backspace/>
                    Back to Blog Posts
                </Button>
                <Card>
                    <CardBody>
                        <Grid container direction={'row'} justify={'space-between'}>
                           <p>Published:{publish.toString()}</p>
                           <p>Last Edited: {FormatDate(lastEdited)}</p>
                        </Grid>
                        <Grid container direction={'column'} justify={'center'}>
                            <h2 style={{alignSelf:'center'}}>{title}</h2>

                        </Grid>
                        <div>
                            {this.renderImages()}
                            <Interweave
                                content={content}
                            />
                        </div>
                        <Grid container direction={'row'} alignItems={'center'} >
                            {photoURL!==''?
                                <Avatar
                                    alt={'avatar'}
                                    src={author.photoURL}
                                />:
                                <Avatar><p>?</p></Avatar>
                            }
                            <div style={{marginLeft:10}}>
                                <p style={{marginBottom:-20}}>Author: {author.displayName}</p>
                                <p>Written: {FormatDate(date)}</p>
                            </div>
                        </Grid>
                    </CardBody>
                </Card>
            </Grid>
        )
    }
}

const mapStateToProps=(state)=>{
    return {
        blog:state.blogs.viewBlog,
        auth:state.auth,

    }
};


export default connect(mapStateToProps, {AddComment, AddReply})(ViewBlog);