import {useReducer}from 'react';


const INITIAL_STATE={
    loading:false,
    notif:false,
    notifMsg:'',
    notifType:'warning',
    image:null,
    imageModal:false,
    filter:false,
    error:null,
    title:'',
    titleError:null,
    bannerTitle:'',
    bannerTitleError:null,
    description:'',
    descriptionError:null,
    content:'',
    contentError:null,
    lastEdited:null
}

const reducer=(state,action)=>{
    const {payload,type}=action;
    switch(type){
        case 'closeNotif':
            return {...state,notif:false,notifType: 'warning',notifMsg: ''}
        case 'errorNotif':
            return {...state,notif:true,notifType:'danger',notifMsg:payload}
        case 'successNotif':
            return {...state,notif:true,notifType:'success',notifMsg:payload}
        case 'loadData':
            return {...state,
                title:payload.title,
                bannerTitle:payload.bannerTitle,
                image:payload.bannerImage,
                filter:payload.filter,
                description: payload.description,
                content:payload.content,
                lastEdited:payload.lastEdited
            }
        case 'clearErrors':
            return {...state,titleError:null,bannerTitleError:null,descriptionError:null,contentError:null }
        case 'error':
            return {...state,[payload.name+'Error']:payload.error}
        case 'content':
            return {...state,content:payload}
        case 'filter':
            return {...state,filter:!state.filter}
        case'description':
            return {...state,description:payload}
        case 'bannerTitle':
            return {...state,bannerTitle:payload}
        case 'title':
            return {...state,title:payload}
        case 'imageSave':
            return {...state,imageModal: false,error:null,image:payload}
        case'imageModal':
            return {...state,imageModal:!state.imageModal}
        case 'loading':
            return {...state,loading:!state.loading}
        default:
            return {...state}
    }
}




export default ()=>{

    const [state,dispatch]=useReducer(reducer,INITIAL_STATE);


    const Validate=(data)=>{
       const keys = Object.keys(data);
       let errors=false;
       keys.forEach(key=>{
           if(data[key].length<=0){
               dispatch({type:'error',payload:{name:key,error:'Please check '+key+' your input!'}})
               errors=true
           }
       })

        return errors;
    }




    return [state,dispatch,Validate];

}