import React,{useState} from "react";
import {connect}from 'react-redux';
import {makeStyles} from "@material-ui/core/styles";
import {List,ListItem,ListItemText,ListItemAvatar,Avatar,Typography,TextField, ListItemSecondaryAction,IconButton} from "@material-ui/core";
import {Delete,VisibilityOff, Visibility,CheckCircle, Backspace} from "@material-ui/icons";
// components
import {FormatDate} from "../../../Functions/FormateDate";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import Button from '../../../components/CustomButtons/Button';
import {PostComment, HideComment,DeleteComment, MarkAsRead} from "../../../Store/Actions/Admin/CommentsActions";
import {Loading} from "../../../components/Loading";
import Danger from "../../../components/Typography/Danger";
import ConfirmModal from "../../../My Components/ConfrimModal";
import history from "../../../history";

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        backgroundColor: theme.palette.background.paper
    },
    fonts: {
        fontWeight: "bold"
    },
    inline: {
        display: "inline"
    },
    hiddenPost:{
        textDecoration:'line-through'
    },
    header:{
        display:'flex',
        justifyContent:'space-between',
        flexDirection:'row',
        alignItems:'center'
    }
}));
const ViewComments=({blogDetails, allComments, PostComment,HideComment,DeleteComment, MarkAsRead})=>{

    const classes = useStyles();
    const comments = blogDetails.blogId?allComments[blogDetails.blogId]:null


    const [newComment,setNewComment]=useState('');
    const [commentError,setCommentError]=useState(null);
    const [loading,setLoading]=useState(false)
    const [error,setError]=useState(null);
    const [commentId,setCommentId]=useState(null);
    const [confirmModal,setConfirmModal]=useState(false);

    const CommentComponent=({comment,id})=>{
        const {author,avatar,text, date,authorId,hidden, read}=comment;

        return (
            <ListItem key={id} divider alignItems="flex-start">
                <ListItemAvatar>
                    <Avatar alt={"avatar"+author} src={avatar} />
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <Typography className={hidden?classes.hiddenPost:classes.fonts}>
                            {author}
                        </Typography>
                    }
                    secondary={
                        <>
                            {FormatDate(date)}
                            <Typography
                                component="span"
                                variant="body2"
                                className={classes.inline}
                                color="textPrimary"
                            >
                                {` - ${text}`}
                            </Typography>
                        </>
                    }
                    // todo-add button to hide comment && read comments
                />
                <ListItemSecondaryAction>
                    {!read?
                        <IconButton onClick={()=>MarkAsRead(blogDetails.blogId,id)} size={'small'} edge={'end'}>
                            <CheckCircle color={'action'}/>
                        </IconButton>:null
                    }


                    {!hidden?
                        <IconButton onClick={()=>handleHideComment(id,true)} size={'small'} edge="end" aria-label="visibility-off">
                            <VisibilityOff/>
                        </IconButton>:
                        <IconButton onClick={()=>handleHideComment(id,false)} size={'small'} edge="end" aria-label="visibility">
                            <Visibility/>
                        </IconButton>
                    }

                    <IconButton onClick={()=>{
                        setConfirmModal(true)
                        setCommentId(id)}} size={'small'} edge="end" aria-label="delete">
                        <Delete/>
                    </IconButton>
                </ListItemSecondaryAction>

            </ListItem>

        )



    }

    const HandlePostComment=()=>{
            if(newComment.length>0){
                setLoading(true);
                PostComment(blogDetails.blogId,newComment,res=>{
                    setLoading(false);
                    if(res.status!==1){
                        setCommentError(res.msg)
                    }else{
                        setNewComment('');
                    }
                })
            }else{
                setCommentError('Input cannot be blank!')
            }
    }

    const handleHideComment=(commentId, state)=>{
        setLoading(true)
        HideComment(blogDetails.blogId,commentId,state,res=>{
            setLoading(false)
            if(res.status!==1){
                setError(res.msg);
            }
        })
    }

    const handleDeleteComment=()=>{
        setConfirmModal(false);
        setLoading(true);
        DeleteComment(blogDetails.blogId,commentId,res=>{
            setLoading(false)
            if(res.status!==1){
                setError(res.msg);
            }
        })
    }

    return (
        <div>
            <ConfirmModal
                open={confirmModal}
                close={()=>{
                    setConfirmModal(false)
                    setCommentId(null)
                }}
                title={'Confirm Delete'}
                saveText={'Delete'}
                saveColor={'danger'}
                saveAction={handleDeleteComment}
                content={'Are you sure you want to delete this comment?'}
            />
            <Loading
                show={loading}
            />
            <Card>
                <CardHeader color={'rose'} className={classes.header}>
                    {blogDetails.blogTitle} - Comments
                    <Button

                        size={'sm'}
                        onClick={()=>history.push('/admin/comments')}
                    >
                        <Backspace/>
                        Back to comments
                    </Button>
                </CardHeader>
                <CardBody>
                    <Danger>{error}</Danger>
                    <TextField
                        placeholder={'Your comment here...'}
                        id={'newComment'}
                        error={!!commentError}
                        helperText={commentError}
                        style={{margin:10}}
                        value={newComment}
                        size={'small'}
                        fullWidth
                        InputProps={{
                            endAdornment:<Button onClick={HandlePostComment} size={'sm'} color={'primary'} round>Post</Button>
                        }}
                        onChange={e=>setNewComment(e.target.value)}
                    />
                    {comments?
                        <List dense className={classes.root}>
                            {Object.keys(comments).map(key=>{
                                return <CommentComponent key={key} comment={comments[key]} id={key}/>
                            })}
                        </List>:null
                    }

                </CardBody>
            </Card>
        </div>
    )
}
const mapStateToProps=(state)=>{
    return {
        blogDetails:state.comments.ViewComments,
        allComments:state.comments.allComments
    }
}

export default connect(mapStateToProps,{PostComment, HideComment,DeleteComment, MarkAsRead})(ViewComments);

