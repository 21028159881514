import React,{useEffect} from "react";
import {connect}from 'react-redux';
import {RemoveRedEye} from "@material-ui/icons";
// components
import history from "../../../history";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import {GetAllComment,LoadBlogComment} from "../../../Store/Actions/Admin/CommentsActions";
import Button from '../../../components/CustomButtons/Button';
import Table from '../../../components/Table/Table';

const AllComments=({comments,blogs, GetAllComment, LoadBlogComment})=>{


    useEffect(()=>{
        if(!comments){
            GetAllComment();
        }

        //return ()=>UnSubscribeComment();
    },[]);

    const renderComments=()=>{
        if(comments && blogs){
            const tableData=Object.keys(comments).reduce((a,v)=>{

                const blogPost = blogs.find((b)=>{
                    return b.id===v
                });
                let total=0;
                let unread=0;
                let hiddenTotal=0;

                Object.keys(comments[v]).forEach(comment=>{
                    const {read,hidden}=comments[v][comment];
                    total+=1;
                    if(!read){
                        unread+=1
                    }
                    if(hidden){
                        hiddenTotal+=1
                    }
                })

                a.push([
                    blogPost.title,
                    total,
                    unread,
                    hiddenTotal,
                    <Button
                        onClick={()=>handleViewComments(v,blogPost.title)}
                        size={'sm'}
                        round justIcon
                        color={'rose'}><RemoveRedEye/></Button>
                ])

                return a;
            },[])

            const tableHead=['Blog Title','Total','Unread','Hidden','Action']
            return (
                <Table
                    tableHead={tableHead}
                    tableHeaderColor={'primary'}
                    tableData={tableData}
                />
            )
        }
        else{
            return (<div>No Comments as yet...</div>)
        }
    }

    const handleViewComments=(blogId,blogTitle)=>{
        LoadBlogComment({blogId,blogTitle},()=>history.push('/admin/comments/view'))
    }

    return (
        <Card>
            <CardHeader color={'primary'}>
                All Comments
            </CardHeader>
            <CardBody>
                {renderComments()}
            </CardBody>
        </Card>
    )

}
const mapStateToProps=(state)=>{
    return {
        comments:state.comments.allComments,
        blogs:state.blogs.data
    }
}
export default connect(mapStateToProps,{GetAllComment, LoadBlogComment})(AllComments);