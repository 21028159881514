import {ADMIN_LOGIN,ADMIN_LOGOUT, ADMIN_UPDATE_DETAILS,ADMIN_UPDATE_IMAGE} from "../../types";

const initialState={
  displayName:'',
  uid:'',
  loggedIn:false,
  email:'',
  photoURL:'',
  previousLogIn:null
};

export default (state=initialState,action)=>{
    switch (action.type) {
        case ADMIN_LOGIN:
            return {
                ...state,
                displayName: action.payload.displayName,
                uid:action.payload.uid,
                loggedIn:true,
                email:action.payload.email,
                photoURL: action.payload.photoURL,
                previousLogIn: action.payload.lastLogin
            };
        case ADMIN_LOGOUT:
            return {
                ...state,
                displayName:'',
                uid:'',
                loggedIn:false,
                email:'',
                previousLogIn: null,
                photoURL: ''
            };
        case ADMIN_UPDATE_DETAILS:
            return{...state,displayName:action.payload.displayName,email:action.payload.email};
        case ADMIN_UPDATE_IMAGE:
            return {...state,photoURL: action.payload};
        default:return {...state};
    }
}