import {LOAD_SETTINGS} from "../../types";

const InitialState={
    primaryColor:'',
    secondaryColor:'',
    tertiaryColor:'',
    primaryFont:'',
    secondaryFont:'',
    loaded:''
};

export default (state=InitialState,action)=>{
  if(action.type===LOAD_SETTINGS){
      const {primaryColor,secondaryColor,tertiaryColor,primaryFont,secondaryFont}=action.payload;
      return {
          primaryColor,
          secondaryColor,
          secondaryFont,
          tertiaryColor,
          primaryFont,
          loaded:true
      }
  }else{
      return{...state};
  }
};