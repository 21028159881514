import React from "react";
import {connect}from 'react-redux';


/* eslint-disable */
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Navbar from "../../components/Navbars/Navbar.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import Sidebar from "../../components/Sidebar/Sidebar";

import routes from "./Routes.js";

import dashboardStyle from "../../assets/jss/material-dashboard-react/layouts/adminStyle";

import image from "../../assets/img/sidebar-2.jpg";
import logo from "../../assets/img/reactlogo.png";
import {getImages} from "../../Store/Actions/Admin/images";
import {loadData} from "../../Store/Actions/Admin/DataActions";
import {getSettings} from "../../Store/Actions/Admin/SettingsActions";
import ViewBlog from "./Blog/ViewBlog";
import EditBlog from "./Blog/EditBlog";
import BlogViews from './Blog/blogViews';
import NewBlog from "./Blog/NewBlog";
import {GetBlogs} from "../../Store/Actions/Admin/BlogActions";
import history from "../../history";
import ViewComments from "./Comments/ViewComments";



const switchRoutes = (
    <Switch>
        {routes.map((prop, key) => {
            if(prop.items){
                return  prop.items.map(item=>{
                    return (
                        <Route
                            path={prop.layout+prop.path+item.path}
                            component={item.component}
                            key={key+item.name}
                            exact
                        />
                    )
                })
            }else{
                return (
                    <Route
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                        exact
                    />
                );
            }
        })
        }
        <Route exact path={'/admin/blog/view'} component={ViewBlog} />
        <Route exact path={'/admin/blog/edit'} component={EditBlog}/>
        <Route exact path={'/admin/blog/new'} component={NewBlog}/>
        <Route exact path={'/admin/blog/views'} component={BlogViews}/>
        <Route exact path={'/admin/comments/view'} component={ViewComments}/>
    </Switch>
);
class Dashboard extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            image: image,
            color: "purple",
            hasImage: true,
            fixedClasses: "dropdown show",
            mobileOpen: false
        };
    }

    getRoute() {
        return this.props.location.pathname !== "/admin/maps";
    }

    handleLogOut=()=>{
        alert('log out called');
    };

    handleDrawerToggle = () => {
        this.setState({ mobileOpen: !this.state.mobileOpen });
    };
    resizeFunction = () => {
        if (window.innerWidth >= 960) {
            this.setState({ mobileOpen: false });
        }
    };
    componentWillMount() {
        if(!this.props.loggedIn){
           history.push('/')
        }else{

            // will need to reconfigure once components completed
            if(!this.props.images.loaded){
                this.props.getImages(res=>{
                    return res.status===1?true:console.log(res.msg)
                });
            }

            if(!this.props.data.loaded){
                this.props.loadData(res=>{
                    return  res.status===1?true:console.log(res.msg);
                })
            }

            if(!this.props.settings.loaded){
                this.props.getSettings(res=>{
                    return  res.status===1?true:console.log(res.msg);
                })
            }

            if(!this.props.blogs.loaded){
                this.props.GetBlogs(res=>{
                    return  res.status===1?true:console.log(res.msg);
                })
            }
        }




    }

    componentDidMount() {
        if (navigator.platform.indexOf("Win") > -1) {
            const ps = new PerfectScrollbar(this.refs.mainPanel);
        }
        window.addEventListener("resize", this.resizeFunction);
    }
    componentDidUpdate(e) {
        if (e.history.location.pathname !== e.location.pathname) {
            this.refs.mainPanel.scrollTop = 0;
            if (this.state.mobileOpen) {
                this.setState({ mobileOpen: false });
            }
        }
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.resizeFunction);
    }
    render() {
        const { classes, ...rest } = this.props;
        return (
            <div className={classes.wrapper}>
                <Sidebar
                    routes={routes}
                    logoText={"My Pale Legs"}
                    logo={logo}
                    image={this.state.image}
                    handleDrawerToggle={this.handleDrawerToggle}
                    open={this.state.mobileOpen}
                    color={this.state.color}
                    {...rest}
                />
                <div className={classes.mainPanel} ref="mainPanel">
                    <Navbar
                        routes={routes}
                        handleDrawerToggle={this.handleDrawerToggle}
                        handleLogOut={this.handleLogOut}
                        {...rest}
                    />
                    {/* On the /maps route we want the map to be on full screen - this is not possible if the content and container classes are present because they have some paddings which would make the map smaller */}
                    <div className={classes.content}>
                        <div className={classes.container}>{switchRoutes}</div>
                    </div>
                    <Footer/>
                </div>
            </div>
        );
    }


}
Dashboard.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps=(state)=>{
  return {
      loggedIn:state.auth.loggedIn,
      images:state.images,
      data:state.data,
      settings:state.settings,
      blogs:state.blogs
  }


};

export default connect(mapStateToProps,{getImages, loadData, getSettings, GetBlogs})(withStyles(dashboardStyle)(Dashboard));
