import React from 'react';
import {connect}from 'react-redux';
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import history from "../../history";
import {Loading} from "../../components/Loading";
import LoginForm from './Auth/loginForm';
import PasswordReset from './Auth/passwordResetForm';
import {LogAdminIn, ForgotPassword, CheckLogin} from "../../Store/Actions/Admin/ProfileActions";

const BgImage = require('../../assets/img/blogBackground.jpg')

class AdminLogin extends React.Component {

    state={
        loading:false,
        error:'',
        form:'login',
        success:''
    };

    onLoginSubmit=(formValues)=>{
        this.setState({loading:true});
        this.props.LogAdminIn(formValues,res=>{
            res.status===1?history.push('/admin/home'):this.setState({loading:false,error:res.msg});
        })
    };

    onPasswordReset=(formValues)=>{
        this.setState({loading:true});
        this.props.ForgotPassword(formValues,res=>{
            res.status===1?
                this.setState({error:'',loading:false,success:'Reset Success, Please check your email to reset your password!'}):
                this.setState({loading:false,error:res.msg})
        })
    };





    componentDidMount() {

        this.props.CheckLogin(res=>{
            if(res.status===1){
                history.push('/admin/home');
            }else{
                this.setState({loading:false})
            }
        })
    }


    render(){
        const classes=this.props;
        const {loading,error, form,success}=this.state;
        return (
            <div style={{
                background:`url(${BgImage})`,
                backgroundPosition:'center top',
                backgroundRepeat:'no-repeat',
                backgroundSize:'cover',
                height:'100vh',
                width:'100%'
            }}
            >
                <Loading show={loading}/>
                <GridContainer justify="center" alignItems="center">
                    <GridItem sm={12} md={4} style={{marginTop:100}}>
                            <Card>
                                <div style={{textAlign:'center'}}>
                                    {form==='login'?
                                        <LoginForm
                                            formError={error}
                                            onSubmit={this.onLoginSubmit}
                                            formChange={()=>this.setState({form:'forgotPassword'})}
                                        />:
                                        <PasswordReset
                                            successMsg={success}
                                            formError={error}
                                            onSubmit={this.onPasswordReset}
                                            formChange={()=>this.setState({form:'login'})}
                                        />
                                    }
                                </div>
                            </Card>

                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}

export default connect(null,{LogAdminIn, ForgotPassword, CheckLogin})(AdminLogin);