import React,{useEffect} from "react";
import {connect}from 'react-redux';
import {makeStyles} from "@material-ui/core/styles";
import {AccessTime} from "@material-ui/icons";
import moment from "moment";

//components
import {getDashData} from "../../../Store/Actions/Admin/DashboardActions";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Card from "../../../components/Card/Card";
import CardBody from "../../../components/Card/CardBody";

import styles from "../../../assets/jss/material-dashboard-react/views/dashboardStyle.js";
import CardFooter from "../../../components/Card/CardFooter";
import CardHeader from "../../../components/Card/CardHeader";
import {LineGraph, DoughnutGraph,HorizontalGraph} from "../../../components/Graphs/Graphs";

const useStyles = makeStyles(styles);

const lessDate=moment().subtract('30','days').format('DD-MM-YYYY');

const DashboardPage=({getDashData,pageData,devices,visitorTypes,sessions})=>{
    const classes = useStyles();

    useEffect(()=>{
        getDashData()
    },[]);

    const devicesCheck=(device)=>{
        if(devices){
            let total=Object.values(devices).reduce((a,v)=>{
                a+=v
                return a},0);
            return (devices[device]/total*100).toFixed(2).toString()+'%'

        }else{
            return '0%'
        }
    }

    const visitorCheck=(type)=>{
        if(visitorTypes){
            let total=Object.values(visitorTypes).reduce((a,v)=>{
                a+=v
                return a},0);
            return (visitorTypes[type]/total*100).toFixed(2).toString()+'%'
        }else{
            return '0%'
        }
    }

    return (
        <GridContainer>
            <GridItem xs={12} sm={8}>
                <Card chart>
                    <CardHeader color={'rose'}>
                        {sessions?
                            <LineGraph
                                label={'User Sessions'}
                                labels={Object.keys(sessions).map(date=>moment(date).format('Do MMM YYYY'))}
                                series={Object.values(sessions)}
                            />
                            :null
                        }
                    </CardHeader>
                    <CardBody>
                        <h4 className={classes.cardTitle}>User Sessions</h4>
                        <p className={classes.cardCategory}>Amount of times a user views the site</p>
                    </CardBody>
                    <CardFooter chart>
                        <div className={classes.stats}>
                            <AccessTime /> today - {lessDate}
                        </div>
                    </CardFooter>
                </Card>
            </GridItem>
            <GridItem xs={12} sm={4}>
                <Card chart>
                    <CardHeader>
                        {devices?
                            <DoughnutGraph
                                labels={Object.keys(devices)}
                                series={Object.values(devices)}
                            />:null
                        }
                    </CardHeader>
                    <CardBody>
                        <h4 className={classes.cardTitle}>Devices</h4>
                        <p className={classes.cardCategory}>Difference in how the users are viewing</p>
                        <div style={{display:'flex',justifyContent:'space-between',flexDirection:'row'}}>
                            <p style={{color:'#1241ad'}}>Mobile: {devicesCheck('mobile')}</p>
                            <p style={{color:'#112c7e'}}>Desktop: {devicesCheck('desktop')}</p>
                        </div>
                    </CardBody>
                    <CardFooter chart>
                        <div className={classes.stats}>
                            <AccessTime /> today - {lessDate}
                        </div>
                    </CardFooter>
                </Card>
            </GridItem>
            <GridItem xs={12} sm={8}>
                <Card chart>
                    <CardHeader color={'rose'}>
                        {pageData?
                            <HorizontalGraph
                                labels={Object.keys(pageData)}
                                series={Object.values(pageData)}
                                label={'Page Data'}
                            />:null
                        }
                    </CardHeader>
                    <CardBody>
                        <h4 className={classes.cardTitle}>Pages</h4>
                        <p className={classes.cardCategory}>Amount of times users have viewed each page</p>
                    </CardBody>
                    <CardFooter chart>
                        <div className={classes.stats}>
                            <AccessTime /> today - {lessDate}
                        </div>
                    </CardFooter>
                </Card>
            </GridItem>
            <GridItem xs={12} sm={4}>
                <Card chart>
                    <CardHeader>
                        {visitorTypes?
                            <DoughnutGraph
                                labels={Object.keys(visitorTypes)}
                                series={Object.values(visitorTypes)}
                            />:null
                        }
                    </CardHeader>
                    <CardBody>
                        <h4 className={classes.cardTitle}>Visitors</h4>
                        <p className={classes.cardCategory}>Difference in visitor types</p>
                        <div style={{display:'flex',justifyContent:'space-between',flexDirection:'row'}}>
                            <p style={{color:'#1241ad'}}>New: {visitorCheck('New Visitor')}</p>
                            <p style={{color:'#112c7e'}}>Returning: {visitorCheck('Returning Visitor')}</p>
                        </div>
                    </CardBody>
                    <CardFooter chart>
                        <div className={classes.stats}>
                            <AccessTime /> today - {lessDate}
                        </div>
                    </CardFooter>
                </Card>
            </GridItem>
            <GridItem xs={12}>
                <p style={{textAlign:'center'}}>All data is pulled from the google analytics api between today and {lessDate}</p>
            </GridItem>
        </GridContainer>
    )

}

const mapStateToProps=state=>{
    return state.dashboard
}
export default connect(mapStateToProps, {getDashData})(DashboardPage);