import React from "react";
import Danger from "../../../components/Typography/Danger";
import {Field, reduxForm} from "redux-form";
import {RenderInput} from "../../../Functions/RenderInputs";
import Button from "../../../components/CustomButtons/Button";

class LoginForm extends React.Component{


    render(){
        const {formError, onSubmit,formChange}=this.props;
        return (
            <form onSubmit={this.props.handleSubmit(onSubmit)} style={{marginBottom:25}}>
                <h3>Welcome To My Pale Legs</h3>
                <h5>Login to the admin panel below</h5>
                <Danger>{formError}</Danger>
                <div style={{padding:20}}>
                    <Field
                        id={'email'}
                        width={true}
                        type={'email'}
                        name={'email'}
                        placeholder={'yourname@email.com'}
                        label={'Email'}
                        component={RenderInput}
                    />
                    <Field
                        id={'password'}
                        width={true}
                        type={'password'}
                        name={'password'}
                        placeholder={'********'}
                        label={'Password'}
                        component={RenderInput}
                    />
                </div>
                <Button
                    type={'button'}
                    simple
                    color={'primary'}
                    onClick={formChange}
                >
                    Forgot Password?
                </Button>
                <Button
                    size={'sm'}
                    color='primary'
                    round
                    style={{width:'70%'}}
                    type={'submit'}
                >
                    Login
                </Button>
            </form>
        )
    }
}
const validate=(formValues)=>{
    const errors={};
    const {email, password}=formValues;
    if(!email){
        errors.email='Please enter a your email!';
    }else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)){
        errors.email='Please enter a valid email address';
    }
    if(!password){
        errors.password ='You must enter a password'
    }
    return errors;
};

const form = reduxForm({
    form:'adminLogin',
    validate
})(LoginForm);

export default (form);