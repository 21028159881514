import React from "react";
import {connect}from 'react-redux';
import {reduxForm,Field} from "redux-form";
import {AddAlarm, Backup,Edit} from "@material-ui/icons";
import Snackbar from "../../../components/Snackbar/Snackbar";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import {RenderInput} from "../../../Functions/RenderInputs";
import Button from "../../../components/CustomButtons/Button";
import {updateAdminDetails, updatePhotoUrl} from "../../../Store/Actions/Admin/ProfileActions";
import {Loading} from '../../../components/Loading/index';
import Avatar from "@material-ui/core/es/Avatar/Avatar";
import ImageModal from "../../../My Components/ImageModal";


class ProfilePage extends React.Component{

    state={
        loading:false,
        photoURL:'',
        previousLogIn:null,
        Snotif:false,
        SnotifMsg:'',
        Enotif:false,
        EnotifMsg:'',
        imageModal:false,
    };

    componentDidMount() {
        const {previousLogIn,photoURL, aboutMe}=this.props;
        this.setState({
            previousLogIn,
            photoURL,
            content:aboutMe
        })
    }

    handleDetailsUpdate=(formValues)=>{
        this.setState({loading:true});
        const {firstName,lastName,email}=formValues;
        this.props.updateAdminDetails({displayName:firstName+' '+lastName,email:email},res=>{
            res.status===1?this.setState({
                loading:false,
                Snotif:true,
                SnotifMsg:'Details Updated successfully!'}):
                this.setState({
                    loading:false,
                    Enotif:true,
                    EnotifMsg:res.msg,
                })

        })
    };

    handleProfileUpdate=(url)=>{
       this.setState({loading:true});

       this.props.updatePhotoUrl(url,res=>{
           res.status===1?
               this.setState({
                   Snotif:true,
                   SnotifMsg:'Profile image changed successfully',
                   photoURL:url,
                   imageModal:false,
                   Enotif:false,
                   EnotifMsg:'',
                   loading:false
               }):
               this.setState({
                   Enotif:true,
                   EnotifMsg:res.msg,
                   loading:false
               })
       })

    };




    render(){
        const { classes } = this.props;
        const {previousLogIn, loading, Snotif,SnotifMsg,Enotif,EnotifMsg, photoURL,imageModal}=this.state;
        return (
            <div>
                <Loading
                    show={loading}
                />
                <Snackbar
                    icon={AddAlarm}
                    place={'br'}
                    color={'success'}
                    open={Snotif}
                    close
                    closeNotification={()=>this.setState({Snotif:false,SnotifMsg: ''})}
                    message={SnotifMsg}
                />
                <Snackbar
                    icon={AddAlarm}
                    place={'br'}
                    color={'danger'}
                    open={Enotif}
                    close
                    closeNotification={()=>this.setState({Enotif:false,EnotifMsg: ''})}
                    message={EnotifMsg}
                />
                <ImageModal
                    show={imageModal}
                    close={()=>this.setState({imageModal:false})}
                    classes={classes}
                    mode={'avatar'}
                    onSave={this.handleProfileUpdate}
                    selectedImage={photoURL}
                />
                <GridContainer>
                    {/* Details*/}
                    <GridItem xs={12} sm={12} md={8}>
                        <Card>
                            <CardHeader color={'success'}>
                                Admin Details
                            </CardHeader>
                            <CardBody>
                                <form onSubmit={this.props.handleSubmit(this.handleDetailsUpdate)}>
                                    <GridContainer>
                                        <GridItem xs={12} sm={6}>
                                            <Field
                                                id={'firstName'}
                                                width={true}
                                                type={'text'}
                                                name={'firstName'}
                                                label={'First Name'}
                                                placeholder={'First Name'}
                                                component={RenderInput}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={6}>
                                            <Field
                                                name={'lastName'}
                                                id={'lastName'}
                                                type={'text'}
                                                label={'Last Name'}
                                                placeholder={'Last Name'}
                                                component={RenderInput}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12}>
                                            <Field
                                                name={'email'}
                                                id={'email'}
                                                type={'email'}
                                                label={'Email'}
                                                placeholder={'name@email.com'}
                                                component={RenderInput}
                                            />
                                        </GridItem>
                                        <GridItem container direction={'row'} justify={'space-between'} >
                                            <p style={{color:'light grey', fontSize:10}}>Previous login: {previousLogIn!==null?new Date(previousLogIn).toString():'No Login details'}</p>
                                            <Button type={'submit'} color={'success'} size={'sm'} round>
                                                Save
                                            </Button>
                                        </GridItem>
                                    </GridContainer>
                                </form>
                            </CardBody>
                        </Card>
                    </GridItem>
                    {/*Avatar Image*/}
                    <GridItem xs={12} sm={12} md={4}>
                        <Card>
                            <CardHeader color={'primary'}>
                                Profile Image
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} container direction={'column'} alignItems={'center'} justify={'center'}>
                                    {photoURL!==''?
                                        <Avatar
                                            src={photoURL}
                                            style={{height: 150,width:150}}
                                        />:
                                            <Avatar style={{height:150,width:150}}><h4>?</h4></Avatar>
                                    }
                                        {
                                            photoURL!==''?
                                             <Button onClick={()=>this.setState({imageModal: true})} size={'sm'} color={'success'} round ><Edit/>Edit</Button>:
                                             <Button onClick={()=>this.setState({imageModal: true})}  size={'sm'} color={'primary'} round><Backup/>Upload</Button>
                                        }

                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}
const validate=formValues=>{
  const errors={};
  const {email,firstName,lastName}=formValues;
    if(!email){
        errors.email='Please enter a your email!';
    }
    else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)){
        errors.email='Please enter a valid email address';
    }

    if(!firstName){
        errors.firstName='Please enter your first name';
    }
    if(!lastName){
        errors.lastName='Please enter your last name';
    }

    return errors;
};

const Form =reduxForm({
    form:'profileForm',
    validate,
    enableReinitialize:true
})(ProfilePage);


const mapStateToProps=(state)=>{
    let firstName=null;
    let lastName=null;

  const {email,previousLogIn,photoURL,displayName}=state.auth;

  if(displayName!==null){
      [firstName,lastName]=displayName.split(' ');
  }

  return {
      previousLogIn,
      photoURL,
      aboutMe:state.data.aboutMe.content,
      aboutMeLastUpdate:state.data.aboutMe.lastEdited,
      initialValues:{
          firstName,
          lastName,
          email}
  }
};

export default connect(mapStateToProps,{updateAdminDetails, updatePhotoUrl})(Form);