import React from "react";
import {connect}from 'react-redux';
import GridContainer from "../../../components/Grid/GridContainer";
import SocialLinksForm from "./Forms/SocialLinksForm";
import {Loading} from "../../../components/Loading";
import Snackbar from "../../../components/Snackbar/Snackbar";
import {AddAlarm} from "@material-ui/icons";
import {updateCategories, updateSocialLinks} from "../../../Store/Actions/Admin/DataActions";
import {saveColors, SaveFonts} from '../../../Store/Actions/Admin/SettingsActions';
import CatergoriesForm from "./Forms/CatergoriesForm";
class SettingsPage extends React.Component{


    state={
        error:'',
        loading:false,
        notif:false,
        notifMsg:'',
        notifType:'warning'
    };

    handleSocialUpdate=(data)=>{
        this.setState({loading:true});
        this.props.updateSocialLinks(data,res=>{
            res.status===1?
                this.setState({
                    loading:false,
                    notif:true,
                    notifType:'success',
                    notifMsg:'Social links updated successfully!',

                }):
                this.setState({
                    loading:false,
                    notif:true,
                    notifType:'danger',
                    notifMsg:res.msg
                })
        })
    };

    handleCatUpdate=(data)=>{

        if(data.length>0){
            this.setState({loading:true});
            this.props.updateCategories(data,res=>{
                res.status===1?
                    this.setState({
                        loading:false,
                        notif:true,
                        notifMsg:'Categories updated successfully',
                        notifType:'success',
                        error:''
                    }):
                    this.setState({
                        loading:false,
                        notif:true,
                        notifMsg:res.msg,
                        notifType:'danger',
                        error:''
                    })
            })
        }else{
            alert('Please enter some categories!')
        }

    };


    render(){
        const {loading, notif, notifMsg, notifType}=this.state;
        return (
            <div>
                <Loading
                    show={loading}
                />
                <Snackbar
                    icon={AddAlarm}
                    place={'br'}
                    color={notifType}
                    open={notif}
                    close
                    closeNotification={()=>this.setState({notif: false, notifType: 'warning', notifMsg: ''})}
                    message={notifMsg}
                />
                <GridContainer>
                    {/*Social Links*/}
                    <SocialLinksForm
                        onSubmit={this.handleSocialUpdate}
                    />
                    {/*Categories*/}
                    <CatergoriesForm
                        onSubmit={this.handleCatUpdate}
                    />
                </GridContainer>
            </div>

        )
    }
}
const mapStateToProps=(state)=>{
        const {primaryColor,secondaryColor,tertiaryColor,primaryFont,secondaryFont}=state.settings;
        const {categories}=state.data;
    return {
        primaryColor,
        secondaryColor,
        tertiaryColor,
        primaryFont,
        secondaryFont,
        categories
    }
};
export default connect(mapStateToProps, {updateSocialLinks, updateCategories, saveColors, SaveFonts})(SettingsPage);