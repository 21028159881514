import {database} from "../../../Firebase";
import {LOAD_BLOGS, EDIT_BLOG, VIEW_BLOG} from "../../types";
import API from "../Api";

export const GetBlogs=(callback)=>dispatch=>{

    const ref = database.ref('blogs');
    ref.on('value',snap=>{

        const blogs = snap.val();
        if(blogs !==null && blogs !== undefined){
            const blogKeys=Object.keys(blogs);

            if(blogKeys.length>0){
                const data = blogKeys.reverse().reduce((a,v)=>{

                    const post = blogs[v];
                    post['id']=v;
                    a.push(post);
                    return a;
                },[]);

                dispatch({type:LOAD_BLOGS,payload:data});
            }
        }

        callback({status:1})
    });
};

export const AddBlog=(data,callback)=>async (dispatch,getState)=>{

    const {displayName,photoURL}=getState().auth;

    data['author']={displayName,photoURL};
    const response = await API.post('/addBlog',data);

    callback(response.data);
};

export const UpdateBlog=(id,data,callback)=>async dispatch=>{
    data['blogId']=id;
    const response = await API.post('/updateBlog',data);
    console.log('response',response);
    callback(response.data);
};

export const DeleteBLog=(id,callback)=> async dispatch=>{
  const response = await  API.post('/deleteBlog',{blogId:id})

    callback(response.data);
};

export const SetBlogEdit=(data,callback)=>dispatch=>{

    dispatch({type:EDIT_BLOG,payload: data});
    callback({status:1})
};

export const SetBlogView=(data,callback)=>dispatch=>{
    dispatch({type:VIEW_BLOG,payload: data});
    callback({status:1})
};

export const AddComment=(id,data,callback)=>(dispatch,getState)=>{

    const {
        author,
        avatarUrl,
        text,
        uid,
    }=data;
    const ref = database.ref(`blogs/${id}/comments`);
    ref.push({
        author,
        avatarUrl,
        text,
        uid,
        date:Date.now(),
        replies:[]
    })
        .then(()=>{
            const blog = getState().blogs.data.reduce((a,v)=>{
                if(v.id===id){
                    a=v;
                }
                return a;
            },{});

            dispatch({type:VIEW_BLOG,payload:blog});
            callback({status:1});
        })
        .catch(err=>callback({status:2,msg:err.message}))
};

export const AddReply=(id,commentId,data,callback)=>(dispatch,getState)=>{
    const {
        author,
        avatarUrl,
        text,
        uid,
    }=data;
    const ref =database.ref(`blogs/${id}/comments/${commentId}/replies`);
    ref.push({
        author,
        avatarUrl,
        text,
        uid,
        date:Date.now(),
    })
        .then(()=>{
            const blog = getState().blogs.data.reduce((a,v)=>{
                if(v.id===id){
                    a=v;
                }
                return a;
            },{});

            dispatch({type:VIEW_BLOG,payload:blog});
            callback({status:1});
        })
        .catch(err=>callback({status:2,msg:err.message}))

};
