import React from 'react';
import Danger from "../../../components/Typography/Danger";
import {RenderInput} from "../../../Functions/RenderInputs";
import Button from "../../../components/CustomButtons/Button";
import {reduxForm, Field} from "redux-form";
import Success from "../../../components/Typography/Success";


class PasswordReset extends React.Component{

    render() {
        const {formError, onSubmit, formChange, successMsg} = this.props;
        return (
            <form onSubmit={this.props.handleSubmit(onSubmit)} style={{marginBottom:25}}>
                <h3>Forgot your password?</h3>
                <p>No problem, simply enter your email below and we'll send you a link.</p>
                <Danger>{formError}</Danger>
                <Success>{successMsg}</Success>
                <div style={{padding: 20}}>
                    <Field
                        id={'email'}
                        width={true}
                        type={'email'}
                        name={'email'}
                        placeholder={'yourname@email.com'}
                        label={'Email'}
                        component={RenderInput}
                    />

                </div>
                <div style={{display:'flex',flexDirection:'row', justifyContent:'space-around'}}>
                    <Button
                        size={'sm'}
                        type={'button'}
                        round
                        style={{width:'40%'}}
                        color={'default'}
                        onClick={formChange}
                    >
                        Login Instead
                    </Button>
                    <Button
                        size={'sm'}
                        color='primary'
                        round
                        style={{width: '40%'}}
                        type={'submit'}
                    >
                        Reset
                    </Button>

                </div>
            </form>
        )
    }
}

const validate=(formValues)=>{
    const errors={};
    const {email}=formValues;
    if(!email){
        errors.email='Please enter a your email!';
    }else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)){
        errors.email='Please enter a valid email address';
    }

    return errors;
};

const form = reduxForm({
    form:'AdminPasswordReset',
    validate
})(PasswordReset);

export default form;
