import React from "react";
import { connect} from 'react-redux';
import BlogComponent from "./Components/BlogComponent";
import {Loading} from "../../../components/Loading";
import Snackbar from "../../../components/Snackbar/Snackbar";
import {AddAlarm} from "@material-ui/icons";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import {UpdateBlog} from "../../../Store/Actions/Admin/BlogActions";
import history from "../../../history";
import {Grid} from "@material-ui/core";
import Button from "../../../components/CustomButtons/Button";
import {Backspace}from '@material-ui/icons';

class EditBlog extends React.Component{

    state={
      loading:false,
      notif:false,
      notifMsg:'',
      notifType:'warning'
    };

    handleSave=(data)=>{
        this.setState({loading:true});
        const {author,id}=this.props.blog;
        data['author']=author;
        this.props.UpdateBlog(id,data,res=>{
            res.status===1?
                this.setState({
                    loading:false,
                    notif:true,
                    notifMsg:'Successfully updated blog',
                    notifType:'success'
                }):
                this.setState({
                   loading:false,
                   notif:true,
                   notifMsg:res.msg,
                   notifType:'danger'
                })
        })
    };

    componentWillMount() {
        if(this.props.blog.title===null || this.props.blog.title=== undefined){
            history.push('/admin/blog')
        }
    }


    render(){
        const {loading,notif,notifMsg,notifType}=this.state;
        const {title,date,publish,images,content,categories,lastEdited,allowComments,tags}=this.props.blog;
        return (
            <div>
                <Loading
                    show={loading}
                />
                <Snackbar
                    icon={AddAlarm}
                    place={'br'}
                    color={notifType}
                    open={notif}
                    close
                    closeNotification={()=>this.setState({notif: false, notifType: 'warning',notifMsg: ''})}
                    message={notifMsg}
                />
                <Card>
                    <CardHeader color={'rose'}>
                        <Grid container justify={'space-between'} direction={'row'} alignItems={'center'}>
                            <h4>Edit Blog Post</h4>
                            <Button onClick={()=>history.push('/admin/blog')} size={'sm'} round>
                                <Backspace/>
                                Back to Blog Posts
                            </Button>
                        </Grid>
                    </CardHeader>
                    <CardBody>
                      <BlogComponent
                        onSubmit={this.handleSave}
                        mode={'edit'}
                        title={title}
                        scheduleDate={date}
                        publish={publish}
                        images={images}
                        content={content}
                        categories={categories}
                        lastEdited={lastEdited}
                        allowComments={allowComments}
                        tags={tags}
                      />
                    </CardBody>
                </Card>
            </div>
        )
    }
}

const mapStateToProps=(state)=>{
    return {
        blog:state.blogs.editBlog
    }
};


export default connect(mapStateToProps, {UpdateBlog})(EditBlog);